/* Font Size START */

.font22 {
    font-size: var(--fontSize22);
}
.font24 {
    font-size: var(--fontSize24);
}
.font28 {
    font-size: var(--fontSize28);
}
.font32 {
    font-size: var(--fontSize32);
}

/* Font Size End */ 
@media (max-width: 1160px) {
    .sm-container {
        padding: 0 15px;
    }
}
@media (max-width: 1080px) {
    .filter-bx{
        padding: 20px 20px 80px;
    }
    .select-drop{
        width: 240px;
    }
    .filter-options a.filter-val{
        width: calc(100% - 8px);
    }
}
@media (max-width: 1024px) {

    :root {
        --fontSize22: calc(18px + (22 - 18) * ((100vw - 380px) / (1024 - 380)));
        --fontSize24: calc(20px + (24 - 20) * ((100vw - 380px) / (1024 - 380)));
        --fontSize28: calc(22px + (28 - 22) * ((100vw - 380px) / (1024 - 380)));
        --fontSize32: calc(24px + (32 - 24) * ((100vw - 380px) / (1024 - 380)));
    }
    .sm-container {
        padding: 0 15px;
    }
    .cater-sec h1 {
        margin: 0 0 25px;
    }
    .cart-item {
        max-width: 50%;
        width: 100%;
        margin: 0 0 15px;
    }
    .cart-innr {
        margin: 0 7px;
    }
    .checkout-table table tbody tr td.prt-title .prtf-flx{
        width: 180px;
        padding-right: 5px;
    }
    .checkout-table.checkout-order table tbody tr td:first-child{
        width: 300px;
    }
    .select-drop{
        width: 210px;
    }
    .filter-options a.filter-val{
        width: calc(100% - 8px);
    }
    .ban-left .ban-img{
        max-width: 340px;
    }
    
}

@media (max-width: 980px) {

    .checkout-block {
        margin: 35px 0 15px;
    }
    .checkout-title {
        margin: 0 0 15px;
        font-size: 34px;
    }
    .checkout-table table tbody tr td.prt-title .prtf-flx{
        width: 320px;
        padding-right: 5px;
    }
    .prot-itme {
        max-width: 33.333%;
    }
    .dt-flx {
        margin: 10px 0 15px;
    }
    .order-top .bottom {
        padding: 20px 16px;
    }

    .checkout-cart-wrap .checkout-table {
        width: 100%;
    }
    .cart-side-table {
        width: 100%;
        padding-left: 0px;
        margin: 15px 0 0;
        position: fixed;
        bottom: -344px;
        left: 0;
        right: 0;
        top: auto;
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
        z-index: 999;
    }
    .cart-side-table.open {
        bottom: 0;
    }
    .cart-side-table .cart-table{
        height: 400px;
        overflow-y: auto;
        padding-top: 56px;
        background: #FFF;
    }
    .cart-table-header {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 100000;
        z-index: 100;
        padding: 15px 10px;
        background: #f4f4f4;
        box-shadow: 0px -6px 40px 0px rgb(0 0 0 / 20%);
    }
    .cart-table-body{
        transition: all 0.5s ease;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between; 
        padding: 10px 15px 10px;  
        min-height: initial; 
    }
    .cart-checkout-button {
        padding: 12px 0 0;
        text-align: center;
        margin: 10px 0 0;
        border-top: 1px solid #e4e4e4;
    }
    .cart-checkout-button a.checkout-btn{
        width: auto;
    }
    .cart-table-body.open{
        height: auto;
        padding: 20px 15px;
        transition: all 0.75s ease;
    }
    .clear-cart-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding: 5px 0 10px;
        background: #FFF;
    }
    .cart-table-bottom{
        width: 100%;
        padding-bottom: 10px;
    }
    .search-orders{
        border-bottom: none;
    }

    .checkout-table table tbody tr td span.unit-sale-txt{
        width: auto;
    }

    .checkout-table table tbody tr.no-line.lin-btn td.vrt-mid.no_product_found{
        width: 100%;
        display: table-cell;
    }
    .checkout-table table tbody tr td .button {
        font-size: 17px;
        font-weight: 600;
    }
    .checkout-table table tbody tr td .button.back-btn {
        display: flex;
        align-items: center;
    }
    .checkout-table table tbody tr td .form-control {
        padding: 3px 5px;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
    }
    
    .cart-table-header h4{
        display: none;
    }
    .cart-mobile-header {
        display: block;
    }
    .cart-total b {
        font-weight: 700;
    }
    .cart-count {
        display: inline-block;
        float: left;
        position: relative;
        padding: 0 10px;
    }
    .cart-count i {
        font-size: 24px;
        color: #ec2f45;
    }
    .cart-count .cart-count-txt {
        position: absolute;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 21px;
        font-size: 12px;
        background: #000;
        border-radius: 50%;
        color: #FFF;
        top: -5px;
        right: 0;
    }
    
    .cart-total {
        display: inline-block;
        float: left;
        padding: 4px 0 0 10px;
    }
    .cart-trig-open {
        display: inline-block;
        float: left;
        padding: 10px 12px 10px;
        position: relative;
    }
    .cart-trig-open a {
        display: block;
    }
    .cart-trig-open a::after {
        position: absolute;
        content: '';
        width: 180px;
        height: 200%;
        left: -90px;
        top: -50%;
    }
    .cart-trig-icon {
        display: block;
        width: 8px;
        height: 8px;
        border-top: 2px solid #ec2f45;
        border-left: 2px solid #ec2f45;
        transform: rotate(45deg);
        position: absolute;
        top: 10px;
    }
    .open .cart-trig-icon {
        transform: rotate(-135deg);
        top: 6px;
    }
    .cart-mob-chkout {
        display: inline-block;
        float: right;
        margin: 0 12px;
    }
    .cart-mob-chkout a.button {
        margin: 0;
        padding: 6px 14px;
        font-size: 14px;
    }
    .checkout-table table tbody td.table-qty {
        padding: 10px;
    }
    .checkout-table table tbody tr td .add-Cart-btn-wrap {
        width: auto;
    }
    .search-orders input[type="text"], .search-orders input.search-ord{
        width: 415px;
    }

    /*** ***/
    .cart-table-body > ul.main-cat-cart li .cart-prod-id{
        width: calc(100% - 160px);
        align-items: center;
    }
    .cart-table-body > ul.main-cat-cart li .cart-prod-total{
        width: 80px;
    }
    .cart-table-body ul.main-cat-cart{
        max-height: initial;
        min-height: 120px;
        position: relative;
    }
    .cart-all-prod-total {
        padding-right: 34px;
    }
    .free-title-blk a.free_edit_btn{
        right: 6px;
    }
    .select-drop{
        width: 195px;
    }
    .ban-left-text {
        padding: 10px 25px;
        position: absolute;
        z-index: 1;
    }
    .ban-left-text::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        background-image: linear-gradient(to right, rgba(0,0,0,0.75) , rgba(0,0,0,0.0));
    }
    .ban-wrap .ban-left::after{
        right: 9%;
    }
    .ban-wrap .ban-left{
        width: 50%;
        overflow: hidden;
    }
    .ban-right{
        width: 50%;
        padding: 0 30px 0 10px;
    }
    .ban-left-text h3 span{
        color: #FFF;
    }

    .ban-right-btn a.button{
        padding: 8px 12px;
    }
    .hm-left-ban, .hm-right-ban{
        height: 120px;
    }
    .hm-right-ban.full-width{
        height: 90px;
    }
    .hm-right-ban .hm-ban-content .ban-right-text {
        width: 50%;
    }
    .hm-left-ban{
        margin-right: 0px;
    }
    .hm-right-ban{
        margin-left: 0px;
    }
    .home-ban-odd-wrap .hm-banner-wrap{
        gap: 12px;
    }
    .orders-dropdown select {
        width: 140px;
    }
    .orders-drop-left .table-search {
        width: 300px;
    }
    .search-orders.orders-search-bx input[type="text"],
    .search-orders.orders-search-bx input.search-ord,
    .search-orders.orders-search-bx select.search-ord{
        width: 280px;
        width: 100%;
    }
    .cookies-on header {
        padding-top: 57px;
    }
    footer .cookie-bar, .cookie-bar{
        top: 0;
        bottom: auto;
    }
    .hdr-notification-bar{
        /*background: #ef6c43;
        color: #FFF;*/
    }

    .checkout-table table tbody tr td.prt-price .amt-ofr-all{
        display: inline-block;
        padding: 0px 8px 2px 0;
    }
    .checkout-table table tbody tr td.prt-price .amt-ofr-get{
        display: inline-block;
    }
    .cart-table-body>ul.main-cat-cart li.cart-empty{
        min-height: 240px;
        padding: 30px;
    }
    
}

@media (max-width: 860px) {

    /* Checkout Table -- START */

    .checkout-table table thead {display: none;}
    .checkout-table table tbody tr {
        display: flex;
        flex-flow: column wrap;
        display: block;
        position: relative;
        min-height: 90px;
        padding: 10px 0;
        padding-left: 70px;
        padding-right: 60px;
        border-bottom: 1px solid #dddddd;
    }
    .checkout-table.checkout-order table tbody tr {
        width: 100%;
        float: left;
    }
    .checkout-table table tbody tr.prt-head-tr{
        min-height: 50px;
        padding: 10px 0 10px;
        width: 100%;
    }
    .checkout-table.checkout-order table tbody tr.prt-subhead-tr {
        min-height: 32px;
        padding: 3px 0 3px;
        width: 100%;
    }
    .checkout-table table tbody tr:first-child {
        border-top: 1px solid #dddddd;
    }
    .checkout-table table tbody tr td::before {
        content: "" attr(data-title) ":";
        content: "";
        font-size: 20px;
        font-weight: 700;
        color: #2B2E34;
        margin: 0 10px 0 0;
        text-align: left;
        width: 100px;
        display: none;
    }
    .checkout-table table tbody tr td.prt-title::before {
        width: 100px;
        display: none;
    }
    .acc-block .checkout-table table tbody tr {
        padding-left: 0;
        display: flex;
        width: 100%;
    }
    .acc-block .checkout-table table tbody tr td{
        display: flex;
        padding: 5px 10px 5px 0px;
        text-align: left;
    }
    .acc-block .checkout-table table tbody tr td::before{
        content: "" attr(data-title) ":";
        font-size: 16px;
        font-weight: 700;
        color: #2B2E34;
        margin: -2px 10px 2px 0;
        text-align: left;
        width: auto;
        display: inline-block;
    }
    .acc-block .checkout-table table tbody tr td.data-product-hide{
        font-weight: 700;
    }
    .my-order-details-pop .checkout-table table tbody tr td.data-product-hide {
        display: block;
        min-width: 100%;
    }
    .acc-block .checkout-table table tbody tr td.data-product-hide::before {
        content: '';
        margin: 0;
    }
    .checkout-table table tbody td.table-qty {
        width: auto;
    }
    .checkout-table table tbody tr td.table-qty.prt-qty {
        padding: 0px 2px 0px 5px;
    }
    .checkout-table table tbody tr td.add-btn-fld{
        margin: 2px 0 2px 8px;
    }
    .checkout-table table tbody tr td.table-tol {
        width: auto;
        font-weight: 600;
        padding-right: 8px;
    }
    .checkout-table table tbody tr td {
        position: static;
        display: inline-block;
        justify-content: flex-start;
        align-items: flex-start;
        width: fit-content;
        -moz-width: max-content;
        padding: 5px 6px;
        /* float: left; */
        vertical-align: middle;
        text-align: right;
    }
    .checkout-table table tbody tr td.prt-head{
        text-align: left;
    }
    .checkout-table table tr td .prt-info {
        margin-top: 1px;
    }
    .checkout-table table tbody tr td.info-sec {
        justify-content: flex-start;
        padding-top: 2px;
    }
    .checkout-table table tbody tr td.prt-title {
        position: static;
        flex-flow: row wrap;
        width: 100%;
        padding-bottom: 0;
    }
    .checkout-table.checkout-order table tbody tr td.prt-title {
        width: 100%;
    }
    .checkout-table table tbody tr td:last-child{
        width: auto;
    }
    .checkout-table table tbody tr td.add-btn-fld{
        width: auto;
    }
    .prt-ico-flx {
        position: absolute;
        top: 25px;
        width: 100%;
        margin-top: 0px;
    }
    .prt-ico-flx::after {
        display: none;
    }
    .checkout-table table tbody tr td .prt-img .prt-ovl {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 60px;
        height: 60px;
        border-radius: 0px;
    }
    .checkout-table table tbody tr td .prt-img .prt-ovl img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    .checkout-table table tbody tr td.prt-price {
        font-weight: 600;
        padding-right: 10px;
    }
    .add-btn-fld button.add-cart-btn{
        padding: 8px 12px 7px;
    }
    .checkout-table table tbody tr td .prtf-flx .prt-ico {
        order: 3;
    }

    .search-orders .search-order-left{
        width: 100%;
    }
    .checkout-table table tbody tr td span.prt-hide-res {
        /* display: none; no hide as of now */
    }
    /* .checkout-table table tbody tr td.prt-title .prtf-flx .prt-img {
        order: 2;
    }
    .checkout-table table tbody tr td.prt-title .prtf-flx .prt-img .prt-ovl {
        margin-right: 0px;
        margin-left: 7px;
    } */
    .checkout-table table tbody tr td.prt-title .prtf-flx {
        flex: 1 1 auto;
        flex-flow: row wrap;
        width: calc(100% - 110px);
        width: 100%;
    }
    .checkout-table.checkout-order table tbody tr td.prt-title .prtf-flx {
        width: 100%;
    }
    .checkout-table table tbody tr td span.prt-img {
        position: absolute;
        order: 2;
        width: 70px;
        height: 70px;
        top: 10px;
        z-index: 1;
    }
    .checkout-table table tbody tr.free_product_list td span.prt-img{
        left: 10px;
    }
    .checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt {
        order: 1;
        padding: 2px 2px 5px 0px;
        line-height: 1.125;
    }
    .checkout-table table tbody tr td[data-title="Ingredient"] {
        text-align: left;
    }
    .checkout-table table tbody tr.no-line {
        flex-flow: row wrap;
        justify-content: flex-end;
        min-height: auto;
    }
    .checkout-table table {
        margin-bottom: 0px;
    }
    .checkout-table table tbody tr.no-line td::before {display: none;}
    .checkout-table table tbody tr td span.strong {
        margin: 0 7px 0 0;
    }
    .checkout-table table tbody tr.total {
        display: flex;
        padding: 4px 0;
        text-align: center;
    }
    .checkout-table table tbody tr.no-line.lin-btn {
        display: block;
        padding: 5px 0 0;
    }
    .checkout-table table tbody tr.no-line.lin-btn td.chk-btn-holder {
        width: 100%;
        display: inline-block;
        text-align: center;
    }
    .checkout-table table tbody tr.no-line.lin-btn td.chk-btn-holder .pay-btn.button {
        width: auto;
        display: inline-block;
        margin: 2px 6px 2px;
    }
    .checkout-table table tbody tr.total td {
        display: inline-block;
        width: auto;
        min-width: 80px;
        text-align: right;
        padding: 5px 0;
        align-self: center;
    }
    .checkout-table table tbody tr.no-line td:empty {
        display: none;
    }
    .checkout-table table tbody tr td.table-del {
        justify-content: flex-end;
        width: auto;
        margin-left: auto;
        padding-left: 0;
        margin-top: -3px;
    }
    .checkout-table table tbody tr td[data-title="Quantity"] {
        margin: 0px 0 0px;
    }
    .checkout-table table tbody tr td.table-del .ico-del:hover::before {
        color: #d30000;
    }
    .checkout-table table tbody tr td.table-del::before {
        display: none;
    }
    .checkout-table table tbody tr td.table-del .ico-del::before {
        content: "";
        font-weight: 600;
        color: #000;
        margin: 0 6px 0 0;
    }
    .checkout-table table tbody tr.no-line.lin-btn td {
        flex: 1 1 auto;
        width: 50%;
    }

    .cart-table-body > ul.main-cat-cart li{
        padding-right: 32px;
    }
    .cart-all-prod-total{
        padding-right: 32px;
    }
    .cart-table-body > ul.main-cat-cart li .cart-prod-delete{
        right: 10px;
    }

    .cart-table-body > ul.main-cat-cart li.free_product_list{
        padding-right: 4px;
    }
    .checkout-table.free-prod-pop table tbody tr{
        padding-left: 0;
        display: flex;
        flex-flow: wrap;
        min-height: auto;
        padding: 8px 0;
    }
    .checkout-table.free-prod-pop table tbody tr td[data-title="Product"] {
        width: 100%;
        text-align: left;
    }
    .checkout-table.free-prod-pop table tbody tr:first-child{
        border-top: none;
    }
    .checkout-table.free-prod-pop table tbody tr td {
        padding: 5px 5px 5px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .checkout-table.free-prod-pop table tbody tr td::before {
        content: "" attr(data-title) ":";
        content: '';
        font-size: 16px;
        font-weight: 700;
        color: #2B2E34;
        margin: 0 10px 0 0;
        text-align: left;
        display: block;
        width: 100px;
        width: auto;
    }
    .checkout-table.free-prod-pop table tr td .check-box {
        margin: 0px 0 0;
    }

    /* Checkout Table -- END */

    /* My Account Table -- START */

    .acc-table thead {
        display: none;
    }
    .acc-table .checkout-table thead.ord-grand-total{
        display: block;
    }
    .acc-table .checkout-table thead.ord-grand-total tr {
        width: 100%;
        display: inline-table;
    }
    .acc-table tbody tr {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    .acc-table tbody tr td {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 1px;
        text-align: left;
    }
    .acc-table tbody tr td[data-title]::before {
        content: "" attr(data-title) ":";
        font-size: 20px;
        font-weight: 700;
        color: #2B2E34;
        margin: 0 10px 0 0;
        text-align: left;
    }
    .acc-table tbody tr td:not([data-title]) {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
    }
    .acc-table tbody tr td:empty {
        display: none;
    }
    .acc-table tbody tr.total {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 10px 0;
    }
    .acc-table tbody tr.total td {
        text-align: right;
    }
    .acc-table tbody tr.total td.left {
        display: block;
        text-align: left;
    }
    .checkout-table .chk-btn-holder.chk-btn-wrap {
        justify-content: center;
        padding: 0px 0px 15px 0;
    }

    /* My Account Table -- END */
    .search-orders input[type="text"], .search-orders input.search-ord {
        width: calc(100% - 1px);
        margin: 0 0 0px;
        height: 44px;
        font-size: 12px;
        float: left;
    }
    .search-orders .button, .search-orders input[type="button"] {
        margin: 10px 0px 0;
        float: left;
    }
    .search-orders .button, .search-orders input[type="button"]{
        height: 40px;
    }
    .search-orders .button.reload-btn{ float: left;}
    .search-orders .button.reload-btn {padding: 12px 20px;}

    .search-orders input.button.reset-btn {
        margin: 10px 6px 0;
        display: none;
    }
    .search-orders {
        padding: 20px 20px;
    }
    .search-orders button.button {
        margin-left: -55px;
        margin-top: 1px;
        height: 42px;
    }
    .search-orders button.button.search-ord-btn {
        margin-top: 1px;
    }
    /*** dev ***/
    .checkout-table.checkout-order table tbody tr.free_product_list_wrap td:first-child{
        width: 100%;
        max-width: initial;
    }
    .checkout-table table tbody tr.free_product_list_wrap{
        padding: 0px;
        min-height: auto;
    }
    tr.free_product_list_wrap td tr.free_product_list {
        display: block;
        width: 100%;
        padding-left: 110px;
        position: relative;
    }
    tr.free_product_list_wrap td tr.free-title-blk{
        min-height: auto;
    }
    tr.free_product_list_wrap td tr.free_product_list td{
        position: static;
        display: inline-block;
        padding: 2px 4px;
        text-align: right;
        float: left;
        width: auto;
        line-height: 1.5;
    }
    tr.free_product_list_wrap td tr.free_product_list td.prt-title {
        padding-left: 4px;
    }
    tr.free_product_list_wrap td tr.free_product_list td.unit-sale-sec,
    tr.free_product_list_wrap td tr.free_product_list td.prt-tal{
        padding-top: 2px;
    }
    tr.free_product_list_wrap td tr.free_product_list td.info-sec{
        padding-top: 4px;
    }
    .free-product-list li .free-prod-price h4{
        font-weight: 600;
    }

    .select-drop{
        width: 100%;
        height: 40px;
        margin: 12px 2px 0;
        display: flex;
        justify-content: flex-start;
    }
    .filter-bx{
        top: 52px;
        padding: 20px 20px 80px;
    }
    .select-drop a.filter-btn {
        padding: 11px 20px 10px;
    }
    .filter-btns{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px 10px 5px;
    }
    .filter-bx::before{
        left: 20px;
        right: 34px;
    }
    .filter-options a.filter-val {
        width: calc(50% - 8px);
        padding: 10px 14px 10px;
    }
    .search-orders-field {
        width: 100%;
    }
    .select-drop select{
        height: 42px;
    }
    .filter-btns button.button {
        margin: 5px 5px 5px;
    }
    .filter-options a.filter-val span.remove-filter{
        top: 12px;
    }
    .filter-options a.filter-val.active span.remove-filter {
        top: 12px;
        right: 12px;
        opacity: 1;
        visibility: visible;
    }
    .filter-options a.filter-val.active:hover span.remove-filter {
        right: 12px;
    }
    .ban-right-btn a.button{
        padding: 10px 12px 8px;
    }
    .ban-left .ban-img {
        max-width: 300px;
    }
    .ban-left-text h3{ font-size: 32px; }
    .ban-left-text h3 span{ font-size: 18px; }
    .fltr-dt-wrap input[type=text] {
        height: 32px;
    }
    .filter-option-mob {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        flex: 1;
    }
    .filter-option-mob.order-filter-option{
        width: fit-content;
    }
    .filter-option-mob .select-drop a.filter-btn {
        padding: 8px 15px 6px;
        border: 1px solid #d4d4d4;
        box-shadow: none;
        font-size: 13px;
        font-weight: 600;
        height: 34px;
    }
    .search-orders .select-drop{
        display: none;
    }
    .filter-option-mob .select-drop {
        display: flex;
    }
    .filter-option-mob .select-drop{
        width: 300px;
        margin: -3px 10px 0px 0;
        justify-content: flex-end;
        align-items: center;
    }
    .acc-ttflx .filter-option-mob .select-drop{
        margin: 0px 10px 0px 0;
    }
    .filter-option-mob .select-drop a.filter-btn.button .filter-icon{
        width: 15px;
        height: 15px;
    }
    .filter-option-mob .select-drop a.filter-btn.active{
        border-color: #ec2f45;
    }
    .filter-option-mob .filter-bx::before {
        left: auto;
        right: 30px;
    }


    .hm-left-ban .hm-ban-content, .hm-right-ban .hm-ban-content{
        padding: 15px 20px;
    }
    .hm-ban-content .ban-right-text {
        width: 60%;
    }
    .hm-ban-content .ban-left-text {
        width: 40%;
    }
    .ban-right-btn a.button{
        margin: 6px 0;
    }
    .hm-left-ban .ban-right-btn {
        padding: 0;
        width: 100%;
        padding-left: 125px;
    }
    .hm-right-ban.full-width .hm-ban-content {
        justify-content: flex-end;
        padding-right: 80px;
    }
    .hm-right-ban.full-width .hm-ban-content .ban-right-text {
        width: 35%;
    }
    .hm-ban-content .ban-right-text h3{
        max-width: initial;
    }

    .order-pop .checkout-table table tbody tr td{
        display: flex;
        flex-wrap: wrap;
    }
    .order-pop .checkout-table table tbody tr td span.offer-tag-wrap {
        width: 100%;
    }
    .order-pop .checkout-table table tbody tr td span.offer-tag {
        display: inline-block;
        clear: both;
    }

    .free-prod-price .pl-15{
        padding-left: 2px;
    }
    .free-product-list li{
        justify-content: flex-start;
    }
    .orders-dropdown select {
        width: 140px;
        height: 42px;
    }
    .search-orders.orders-search-bx input[type="text"],
    .search-orders.orders-search-bx input.search-ord,
    .search-orders.orders-search-bx select.search-ord{
        width: 280px;
        width: 100%;
        height: 42px;
    }
    .search-orders .button.resetbtn,
    .search-orders button.resetbtn,
    .search-orders input[type="button"].resetbtn{
        height: 42px;
    }

    .thank-head {
        padding: 125px 10px 120px;
    }
    .thank-head h3{
        font-size: 32px;
    }
    .thank-ico{
        width: 70px;
        height: 70px;
        line-height: 90px;
    }
    .thank-ico .fa {
        font-size: 40px;
    }    
    .thank-btn {
        font-size: 17px;
        padding: 12px 20px;
        margin: 30px 0 0px;
    }
    .thank-details-content {
        grid-template-columns: repeat(1, 1fr);
    }
    .thank-details-content .thank-details-item{
        border-right: none;
        border-bottom: 1px solid #e4e4e4;
    }
    .thank-details-content .thank-details-item:last-of-type {
        border-bottom: none;
    }
    .thank-details-title p {
        font-size: 16px;
    }

    .order-pop.my-order-details-pop .modal-table .table_overflow{
        padding: 0;
    }
    .order-pop.my-order-details-pop .modal-table .checkout-table table tbody tr{
        padding: 10px 15px;
    }
    .no_product_found .no_product_txt{
        font-size: 19px;
        padding: 20px 40px 120px;
        background-size: 120px;
    }

    .checkout-table table tbody tr td.prt-price .amt-ofr-get {
        display: inline-block;
    }
    .checkout-table table tbody tr td.prt-price .amt-ofr-all{
        padding: 0px 8px 0px 0;
    }

}

@media (max-width: 780px) {

    .prod-slider-list .prod-slider{
        margin: 0 0 10px;
    }
    .checkout-header{
        padding-bottom: 10px;
        flex-wrap: wrap-reverse;
        padding-top: 10px;
    }
    .checkout-title {
        margin: 0 0 15px;
        font-size: 32px;
        width: 100%;
        padding: 15px 20px 0 0;
    }
    form.form-delivery-date {
        width: 100%;
        order: -1;
    }
    .dt-title{
        margin: 0px 0 10px;
    }
    .acc-block {
        position: relative;
        margin: 40px 0 40px;
    }
    .acc-ttflx{
        flex-wrap: wrap-reverse;
    }
    .dt-flx {
        margin: 15px 0 10px;
    }
    .client-detail-form .form-field{
        width: 100%;
        margin: 0 10px;
    }

    .rdt_Table .rdt_TableBody .rdt_TableCell a.view-btn,
    .rdt_Table .rdt_TableBody .rdt_TableCell a.re-Ord-btn{
        font-size: 13px;
    }
    .info-innr p {
        font-size: 14px;
    }
    .offer_products .info-item .info-innr{
        width: 100%;
    }
    .free_edit_btn.title-tooltip[data-title]:hover:before,
    .free_edit_btn.title-tooltip[data-title]:hover:after{
        display: none;
    }
    .site-maintanance-wrap p {
        font-size: 18px;
    }
    .site-maintanance-wrap p span{
        font-size: 32px;
    }

    /*** Free product list ****/
    .checkout-header h1.page-title {
        width: 100%;
    }
    .page-title{
        font-size: 28px;
        margin: 0 0 15px;
    }
    .page-title span {
        font-size: 24px;
    }
    .free-product-list li .free-prod-id{
        width: 300px;
        min-width: 300px;
    }
    .free-prod-list-pop .free-product-list li .free-prod-id{
        width: 100%;
    }
    .free-prod-id .free-prod-img{
        width: 52px;
        height: 51px;
    }
    .free-prod-id .free-prod-title {
        width: calc(100% - 80px);
    }
    .free-prod-list-pop .free-product-list li.prod-list-header{
        display: none;
    }
    .free-prod-list-pop .free-product-list li .free-prod-unit {
        padding-left: 64px;
    }
    .free-prod-list-pop .order-top h4 span {
        font-size: 18px;
    }
    .free-prod-wrap.info-poup .order-top h4{
        font-size: 18px;
    }
    .free-prod-wrap.info-poup .order-top h4 span{
        display: initial;
        font-size: 14px;
    }
    .free-prod-wrap.info-poup .order-top h4 span.fw-block {
        display: block;
    }
    .ban-wrap .ban-left {
        width: 100%;
    }
    .ban-left .ban-img {
        max-width: 100%;
        width: 65%;
    }
    .ban-left-text {
        padding: 40px;
    }
    .ban-right {
        width: 100%;
        padding: 10px 30px 10px 10px;
    }
    .ban-left-text::before{
        width: 150%;
    }
    .ban-wrap .ban-left::after {
        right: auto;
        left: calc(75% - 25px);
    }
    .ban-right {
        width: 100%;
        padding: 12px 20px 12px;
        background: #fcc254;
    }
    .ban-right .ban-right-text {
        width: 100%;
        text-align: center;
    }
    .ban-right-btn {
        position: absolute;
        right: 5%;
        top: calc(50% - 27px);
        transform: translateY(-50%);
        z-index: 2;
    }
    .free-product-wrap h5 {
        font-size: 20px;
    }
    .filter-option-mob .back-to-cat {
        margin: 0;
        height: fit-content;
    }
    .back-to-cat a.back-btn, .acc-ttflx a.acc-back{
        height: 34px;
    }
    .filter-option-mob {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
    }
    .filter-option-mob .filter-bx::before {
        border-left: 1px solid #f1f1f1;
        border-top: 1px solid #f1f1f1;
    }
    .filter-option-mob{
        position: relative;
        flex-wrap: wrap;
        align-items: center;
    }
    .filter-option-mob .select-drop {
        width: auto;
        position: static;
        margin: 0px 0 1px;
    }
    .filter-options a.filter-val span.remove-filter{
        width: 14px;
        height: 14px;
    }
    .filter-options a.filter-val.active span.remove-filter{
        top: 10px;
        right: 12px;
    }
    .free-prod-wrap .info-bdy.offer_products{
        padding: 12px 0 0px;
        bottom: 10px;   
    }
    .checkout-table.free-prod-pop table tbody tr{
        padding: 6px 0;
    }
    .free-edit-desp{
        margin: 10px 0 5px;
    }
    .free-prod-wrap .info-bdy.offer_products::after{
        height: 10px;
    }
    .filter-option-mob.order-filter-option{
        margin: 0px 0 -2px;
        position: static;
    }
    .acc-ttflx .filter-option-mob.order-filter-option {
        order: 1;
    }
    .header-tab-menu{
        width: 100%;
    }
    main.main-section{
        padding-bottom: 40px;
    }

    .hm-left-ban, .hm-right-ban {
        height: 90px;
        width: 100%;
    }
    .hm-right-ban{
        margin-top: 0px;
        margin-left: 0;
    }
    .hm-left-ban{
        margin-right: 0;
    }
    .hm-ban-content .ban-left-text {
        width: 30%;
    }
    .hm-ban-content .ban-right-text {
        width: 40%;
    }
    .hm-left-ban .ban-right-btn,
    .hm-right-ban .ban-right-btn {
        padding: 0;
        width: auto;
        padding-left: 0;
        position: static;
        transform: initial;
    }
    .hm-left-ban .ban-right-btn{
        width: 30%;
        text-align: right;
    }
    .hm-right-ban.full-width .hm-ban-content {
        padding-right: 20px;
    }
    .hm-right-ban .hm-ban-img img.banImg {
        opacity: 0.45;
    }
    .hm-banner-wrap{
        margin: 20px 6px 0;
    }
    .title-tooltip[data-title]:hover:before{
        font-size: 11px;
    }
    .title-right[data-title]:hover:before {
        left: calc(50% - 25px);
        right: auto;
        margin-left: 0;
        margin-top: 5px;
        left: -15px;
        top: 100%;
    }
    .title-right[data-title]:hover:after {
        top: calc(100% - 5px);
        left: 50%;
        margin-left: -8px;
        margin-top: 0;
        border-right: 0.25em solid #222;
        border: 6px dashed transparent;
        border-bottom: 6px solid #222;
    }
    
    div.cont-shoping{
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px 10px;
        position: sticky;
        top: 0;
        z-index: 2;
    }
    .cont-shoping p {
        width: 100%;
        text-align: center;
        margin: 0 0 10px;
    }
    #clientDetails .client-detail-bx {
        height: 0px;
    }
    #clientDetails.show .client-detail-bx {
        height: 240px;
        padding-top: 5px;
    }
    .act-show{
        cursor: pointer;
    }
    .act-show::after {
        display: inline-block;
    }
    .prod_tableQty {
        width: 84px;
    }
    .checkout-table table tbody tr{
        padding-right: 10px;
    }
    .checkout-table table tbody tr td.prt-qty .prod_tableQty input{
        width: 84px;
        height: 30px;
        padding: 2px 26px;
    }
    .prod_tableQty .prod_QtyVal{
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .prod_tableQty .prod_QtyVal.prod_QtyMin{
        line-height: 27px;
    }

    .cart-table-body > ul.main-cat-cart li .cart-prod-qty, .cart-prod-qty {
        width: 80px;
    }
    .cart-prod-qty input.qty-number{
        height: 30px;
    }
    .cart-prod-qty .qty-val-action{
        width: 28px;
        height: 28px;
        font-size: 20px;
        line-height: 29px;
        top: 1px;
        right: 11px;
    }
    .cart-prod-qty .qty-val-minus {
        left: 1px;
        top: 1px;
        line-height: 26px;
    }
    .promo-serve-bx{
        width: 100%;
    }
    .promo-serve-form{
        padding: 0px 0 10px 0px;
        border-left: none;
    }

    .free-prod-list-pop .free-product-list li {
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 70px;
        min-height: 80px;
    }
    .free-prod-list-pop .free-prod-id .free-prod-img{
        position: absolute;
        left: -60px;
        top: -2px;
    }
    .free-prod-list-pop .free-prod-id .free-prod-title{
        padding-left: 6px;
    }
    .free-prod-list-pop .free-product-list li .free-prod-unit{
        min-width: auto;
        padding-left: 6px;
        padding-right: 0;
    }
    .free-prod-list-pop .free-product-list li .free-prod-price h4 .amt-ofr-all{
        display: inline-block;
    }

}

@media (min-width: 769px) {
    /*** notification desktop ***/
    body.popup-desktop{
        overflow: hidden;
    }
    .popup-desktop footer {
        z-index: 9;
    }
    .hdr-notification-bar.notification-Popup.popup-desktop {
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hdr-notification-bar.notification-Popup.popup-desktop .notification-content {
        padding: 25px 30px 15px 30px;
        /*position: absolute;
        top: 50%;
        left: 50%; */
        z-index: 4;
        width: 90%;
        max-width: 480px;
        background: #f3ecdb;
        border-radius: 14px;
        -webkit-animation: growIn .5s forwards; 
        animation: growIn .5s forwards;
        animation-delay: 0.25s;
        -webkit-animation-delay: 0.25s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
    .notification-Popup.popup-desktop .notification-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
    }
    .notification-Popup.popup-desktop .notify-txt span.icon{
        display: inline-block;
        margin: -15px 0 15px;
    }
    .notification-Popup.popup-desktop .notification-content .notify-txt {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .notification-Popup.popup-desktop .notify-txt p {
        width: calc(100% - 0px);
    }
    .hdr-notification-bar.notification-Popup.popup-desktop .notification-buttons{
        display: block;
    }
    .hdr-notification-bar.notification-Popup.popup-desktop .notification-buttons a.button.ok-btn{
        color: #FFF;
    }
    .hdr-notification-bar.notification-Popup.popup-desktop .notification-buttons a.button.ok-btn:hover{
        background: #fcc254;
        color: #332e20;
    }
    .text-center-desktop.hdr-notification-bar p{
        text-align: center;
    }
    .text-left-desktop.hdr-notification-bar p{
        text-align: left;
    }

}

@media (max-width: 768px) {
    /*** continue shoping pop ***/
    .info-bdy .cart-flx .cart-item {
        max-width: 100%;
        min-height: auto;
    }
    .info-bdy .cart-flx .cart-item .cart-top{
        height: auto;
    }
    .info-bdy .cart-flx .cart-item a.cart-innr{
        max-width: 100%;
    }

    /*** notification mobile ***/
    body.popup-mobile{
        overflow: hidden;
    }
    .popup-mobile footer {
        z-index: 9;
    }
    .hdr-notification-bar.notification-Popup.popup-mobile {
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hdr-notification-bar.notification-Popup.popup-mobile .notification-content {
        padding: 25px 30px 15px 30px;
        /*position: absolute;
        top: 50%;
        left: 50%; */
        z-index: 4;
        width: 90%;
        max-width: 480px;
        background: #f3ecdb;
        border-radius: 14px;
        -webkit-animation: growIn .5s forwards; 
        animation: growIn .5s forwards;
        animation-delay: 0.25s;
        -webkit-animation-delay: 0.25s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
    .notification-Popup.popup-mobile .notification-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
    }
    .notification-Popup.popup-mobile .notification-content .notify-txt {
        display: flex;
        flex-wrap: wrap;
    }
    .notification-Popup.popup-mobile .notify-txt span.icon{
        display: inline-block;
    }
    .notification-Popup.popup-mobile .notify-txt p {
        width: calc(100% - 40px);
        padding-left: 20px;
    }
    .hdr-notification-bar.notification-Popup.popup-mobile .notification-buttons{
        display: block;
    }
    .hdr-notification-bar.notification-Popup.popup-mobile .notification-buttons a.button.ok-btn{
        color: #FFF;
    }
    .hdr-notification-bar.notification-Popup.popup-mobile .notification-buttons a.button.ok-btn:hover{
        background: #fcc254;
        color: #332e20;
    }
    .text-center-mob.hdr-notification-bar p{
        text-align: center;
    }
    .text-left-mob.hdr-notification-bar p{
        text-align: left;
    }
    .thank-body-inner.loading-buffer::after {
        top: calc(15% - 15px);
    }


}

@media (max-width: 680px) {
    .prot-itme {
        max-width: 50%;
    }
    .checkout-title {
        font-size: 26px;
        margin: 0 0 8px;
    }
    .cater-item {
        max-width: 100%;
    }
    .cater-innr {
        padding: 10px;
    }
    .order-innr {
        padding: 5px;
    }
    .prt-bdy-img {
        max-width: 300px;
        max-height: 300px;
    }
    .prt-poup.prt-img-poup .prt-bdy-img{
        height: 300px;
    }
    .prt-poup.prt-img-poup .prt-bdy-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .checkout-table table tbody tr.total {
        padding: 2px 0;
        text-align: right;
    }
    .checkout-table table tbody tr.total {
        padding: 2px 0;
        text-align: right;
        width: 100%;
    }
    .checkout-table table tbody tr.total td{
        text-align: left;
        vertical-align: initial;
        min-width: 150px;
    }
    .checkout-table table tbody tr td span.strong{
        min-width: 115px;
    }
    .checkout-table table tbody tr.total td.table-tol.prt-tal{
        min-width: 100px;
        text-align: right;
        align-self: end;
    }

    .cart-table-body > ul.main-cat-cart li .cart-prod-id{
        width: 100%;
        margin-bottom: 10px;
    }
    .cart-table-body > ul.main-cat-cart li{
        justify-content: space-between;
    }
    .acc-table .checkout-table thead.ord-grand-total tr td {
        font-size: 16px;
    }
    .acc-table .order-pop .checkout-table thead.ord-grand-total tr td{
        text-align: left;
    }
    .cart-table-body > ul.main-cat-cart li .cart-prod-qty, .cart-prod-qty{
        width: 90px;
        margin-left: 20px;
    }
    .dt-flx .delivery-date .dt-innr {
        margin-right: 0;
    }
    .cart-prod-qty input.qty-number{
        height: 30px;
    }
    .cart-prod-qty .qty-val-action{
        width: 30px;
        height: 28px;
        font-size: 20px;
        line-height: 29px;
        top: 1px;
        right: 11px;
    }
    .cart-prod-qty .qty-val-minus {
        left: 1px;
        top: 1px;
        line-height: 26px;
    }
    .check-box label{
        padding-left: 32px;
        display: inline-block;
        text-align: left;
    }
    .check-box label:before{
        position: absolute;
        left: 0px;
    }
    .check-box input:checked + label:after{
        left: 8px;
        top: 3px;
    }
    .order_no_details {
        width: 100%;
        max-width: 100%;
    }
    .order_account_details {
        width: 100%;
        max-width: 100%;
        margin: 18px 0 0;
        padding: 18px 0 0;
        border-top: 1px solid #e5e5e5;
    }
    .checkout-table table tbody tr td.add-btn-fld {
        padding: 0;
        margin: 2px 0 2px 6px;
    }
    .cookie-bar {
        padding: 6px 40px 7px 20px;
        border-bottom: 1px solid #444;
    }
    .hdr-notification-bar p{
        font-size: 15px;
    }
    .search-orders {
        padding: 15px 15px;
    }
    .search-orders .button, .search-orders input[type="button"]{
        margin: 10px 0 0;
    }
    .cart-table-body > ul.main-cat-cart li .cart-prod-delete {
        top: auto;
        bottom: 21px;
    }

    /*** ***/
    tr.free_product_list_wrap td tr.free_product_list td.table-tol {
        /* clear: left; */
    }
    tr.free_product_list_wrap td tr.free_product_list td span.prt-hide-res {
        margin: 1px 0 0;
    }
    .cart-all-prod-total {
        padding-right: 20px;
    }
    .checkout-table table tbody tr td span.vat-txt{
        display: block;
    }
    .order-pop td.td-vat-fee span.vat-txt {
        font-size: 15px;
        display: block;
    }
    .checkout-table table tbody tr td span.strong {
        font-size: 18px;
        text-align: right;
    }
    .checkout-table table tbody tr td.prt-head span.strong {
        text-align: left;
    }
    .checkout-table.checkout-order table tbody tr td[data-title="Quantity"]{
        clear: left;
    }
    .checkout-table.checkout-order table tbody tr td .cart-prod-qty{
        margin-left: 0;
    }
    .checkout-table.checkout-order table tbody tr td.table-tol{
        margin: 6px 0 0;
    }
    .checkout-table.checkout-order table tbody tr.total td.table-tol{
        margin: 1px 0 0;
    }
    .checkout-table table tbody tr td.table-del{
        position: absolute;
        right: 2px;
        bottom: 13px;
    }
    .checkout-block{
        padding: 0 0px;
    }
    .checkout-table table tbody tr td[data-title="Quantity"] {
        margin: 1px 0 0;
    }
    .checkout-table table tbody tr td .free-title-blk h4 {
        width: calc(100% - 40px);
    }
    .checkout-table table tbody tr td .free-title-blk span.ico-del{
        right: 2px;
    }
    .checkout-table table thead.ord-grand-total tr td:last-child {
        width: 40px;
    }
    /*
    .free-title-blk.free-select-blk h4 {
        width: 100%;
    }
    .free-title-blk.free-select-blk .free_edit_btn{
        position: relative;
        right: auto;
        top: 2px;
    }
    */

    /*** Free product list ****/
    .free-product-list li .free-prod-id,
    .free-prod-list-pop .free-product-list li .free-prod-id {
        max-width: initial;
        width: 100%;
        padding: 0 0 3px;
    }
    .free-prod-id .free-prod-title {
        display: inline-block;
        width: calc(100% - 12px);
        padding-left: 52px;
        padding-bottom: 0px;
    }
    .free-prod-list-pop .free-prod-id .free-prod-title {
        display: inline-block;
        width: calc(100% - 12px);
        padding-left: 6px;
    }
    .free-prod-list-pop .free-prod-id .free-prod-title {
        width: calc(100% - 0px);
    }
    .free-prod-id .free-prod-img {
        width: 42px;
        height: 41px;
        margin-right: 10px;
        position: absolute;
        top: 0px;
    }
    .free-prod-id .free-prod-img img{
        border-radius: 4px;
    }
    .free-product-list li .free-prod-unit{
        padding-left: 52px;
        padding-right: 5px;
    }
    .free-product-list li .free-prod-info {
        padding: 0 5px;
        min-width: 30px;
    }
    .free-product-list li .free-prod-price {
        padding-left: 5px;
    }
    .free-product-list li.prod-list-header {
        display: none;
    }
    .free-prod-list-pop .free-product-list li {
        padding-left: 60px;
    }
    .free-prod-list-pop .free-prod-id .free-prod-img {
        width: 42px;
        height: 41px;
        position: absolute;
        left: -47px;
    }
    .free-prod-list-pop .free-product-list li .free-prod-unit {
        /*padding-left: 54px; set position*/
        padding-left: 6px;
        min-width: auto;
    }
    .free-prod-list-pop .free-product-list li .free-prod-price {
        padding-left: 8px;
        padding-top: 1px;
    }
    .select-drop{
        width: 100%;
    }
    .filter-option-mob .select-drop a.filter-btn{
        width: 130px;
        padding: 8px 10px 7px;
    }
    .filter-option-mob .select-drop a.filter-btn.button .filter-icon{
        width: 10px;
    }

    .cookies-on footer {
        padding: 15px 0 5px;
    }
    .cart-prod-id .prod-title-txt{
        width: calc(100% - 15px);
    }

    .filter-bx.show{
        width: calc(100% - 20px);
        right: 10px;
        top: 46px;
    }
    .title-tooltip[data-title]:hover:before{
        max-width: 150px;
        font-size: 10px;
    }
    .cart-prod-id .prod-title-txt .title-tooltip[data-title]:hover:before{
        max-width: 80px;
    }
    .orders-drop-left .table-search {
        width: 270px;
    }
    main.main-section{
        padding-bottom: 60px;
    }

    .no_product_found .no_product_txt{
        font-size: 16px;
        padding: 10px 30px 100px;
        background-size: 100px;
    }
    .checkout-table table tbody tr.no-line.lin-btn td.vrt-mid.no_product_found {
        width: 100%;
    }
    .thank-body-inner.loading-buffer .loading-txt {
        font-size: 1.225em;
    }
    .notify-txt{
        justify-content: center;
    }
    .notification-Popup.popup-mobile .notify-txt p,
    .notification-Popup.popup-all .notify-txt p {
        width: calc(100% - 0px);
        padding-left: 0px;
    }
    .notification-Popup.popup-mobile .notify-txt span.icon,
    .notification-Popup.popup-all .notify-txt span.icon{
        margin: -10px 0 10px;
    }

    .free-product-list li .free-prod-price h4 .amt-ofr-all{
        display: inline-block;
    }
    .login{
        padding: 0 15px;
    }
    .login .form{
        padding: 30px 20px 20px;
    }
    .form .login-form h2 {
        font-size: 26px;
    }


}

@media (max-width: 600px) {
    .ban-left .ban-img {
        max-width: 100%;
        width: 100%;
    }
    .ban-left-text {
        padding: 15px;
        position: relative;
        width: 50%;
    }
    .ban-left-text h3 span {
        color: #332e20;
    }
    .ban-left-text::before {
        background: transparent;
        width: 100%;
    }
    .ban-left-text::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 40px;
        background: #fcc254;
        top: 25px;
        right: 15px;
    }
    .ban-wrap .ban-left::after{
        visibility: hidden;
    }
    .ban-right-btn {
        right: 25%;
        top: auto;
        bottom: 36px;
        transform: translateY(-65%) translateX(45%);
    }

    .hm-right-ban .hm-ban-content .ban-right-text {
        width: 60%;
    }
    .hm-banner-wrap .hm-right-ban .hm-ban-content .ban-right-text {
        width: 100%;
    }

    .hm-right-ban.full-width .hm-ban-content .ban-right-text {
        width: 60%;
    }

    .hm-ban-content .ban-right-text h3{
        font-size: 18px;
    }
    .hm-ban-content .ban-right-text h3 span{
        font-size: 16px;
    }
    .cookies-on header {
        padding-top: 75px;
    }
    a.button.cookie-btn{
        margin: 8px auto 0px;
        display: grid;
        max-width: fit-content;
    }

    .orders-dropdown{ width: 100%; margin: 0 0 10px; padding: 0;}
    .orders-dropdown select{ width: 100%;}
    .table-search-reset {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .search-orders .button.resetbtn, .search-orders button.resetbtn, .search-orders input[type="button"].resetbtn{
        width: 100%;
        margin: 10px 0 0;
    }
    .orders-drop-left, .products-search-left{
        width: 100%;
    }
    .table-search {
        width: 100%;
    }
    .orders-drop-left .table-search{
        width: 95%;
    }
    .search-orders.orders-search-bx input[type="text"]{
        width: calc(100% + 10px);
        height: 44px;
    }
    .fltr-dt-range .fltr--date {
        width: 100%;
    }
    .header-tab-menu > a{
        font-size: 16px;
        padding: 10px 8px 10px;
        margin: 0 3px;
    }
    .newProd-wrap .cater-item .cater-innr{
        font-size: 18px;
    }

    /*** continue shoping pop ***/
    .info-bdy .cart-flx .cart-item img.cart-back {
        height: 80px;
    }
    .info-bdy .cart-flx .cart-item .cart-top p {
        font-size: 14px;
    }

}

@media (max-width: 560px) {
    .cookies-on header {
        padding-top: 80px;
    }
    .cont-shoping p {
        font-size: 16px;
    }
    .thank-head h3 {
        font-size: 28px;
    }
    main.main-section{
        padding-bottom: 70px;
    }
    .cookie-bar p {
        font-size: 15px;
    }

    .checkout-table table tbody tr {
        padding-right: 10px;
        padding-bottom: 15px;
        float: left;
        width: 100%;
    }
    .checkout-table table tbody tr td{
        float: left;
    }
    .checkout-table table tbody tr td.table-qty.prt-qty {
        padding: 0px 2px 0px 5px;
        clear: left;
    }
    .checkout-table.checkout-order table tbody tr td.table-tol {
        margin: 8px 0 0;
    }
    .checkout-table table tbody tr td.table-del{
        bottom: 19px;
        margin-top: 0px;
    }
    .subhead {
        font-size: 15px;
    }
}

@media (max-width: 492px) {
    .dt-flx {
        margin: 20px 0 10px;
    }
    .order_no_details p span.ord-det-txt,
    .order_account_details p span.ord-det-txt{
        width: 100%;
    }
    .order_no_details p span.pay,
    .order_account_details p span.pay,
    .order_no_details p span.date,
    .order_account_details p span.date{
        width: 100%;
    }
    .page-title{
        font-size: 22px;
        margin: 0 0 15px;
    }
    .page-title span {
        font-size: 18px;
    }
    .filter-bx {
        top: 52px;
        padding: 20px 20px 90px;
    }
    .filter-btns{
        padding: 15px 15px 5px;
    }
    .filter-options a.filter-val {
        width: calc(100% - 8px);
    }   
    .filter-btns button.button{
        padding: 10px 10px;
        height: auto;
    } 

    .ban-right {
        width: 100%;
        padding: 0px 10px 10px;
        background: transparent;
    }
    .ban-left-text {
        padding: 10px;
        width: 100%;
    }
    .ban-right-btn a.button{
        margin: 10px 0 0;
    }
    .ban-right .ban-right-text p span {
        display: initial;
    }
    .ban-right .ban-right-text p {
        font-size: 14px;
    }

    a.button.cookie-btn{
        margin: 8px auto 0px;
        display: grid;
        max-width: fit-content;
    }
    .cookies-on header {
        padding-top: 80px;
    }
    .cookie-bar p {
        font-size: 14px;
    }
    .hdr-notification-bar p{
        font-size: 14px;
    }

    /*** new banner ***/
    .hm-left-ban {
        height: 160px;
    }
    .hm-right-ban {
        height: 130px;
    }
    .hm-ban-content .ban-left-text::after{
        visibility: hidden;
    }
    .hm-ban-content .ban-left-text{
        width: auto;
    }
    .hm-ban-content .ban-left-text .ofr-icon{
        left: -10px;
        top: -2px;
    }
    .hm-left-ban .hm-ban-content, .hm-right-ban .hm-ban-content{
        justify-content: center;
    }
    .hm-ban-content .ban-right-text {
        width: 80%;
        text-align: center;
    }
    .hm-left-ban .ban-right-btn,
    .hm-right-ban .ban-right-btn {
        width: 80%;
        text-align: center;
    }

    .hm-right-ban.full-width {
        height: 130px;
    }
    .hm-right-ban.full-width .hm-ban-content {
        justify-content: center;
    }

    .hm-ban-content .ban-left-text h3 span{
        display: inline-block;
        font-size: inherit;
    }
    .hm-banner-wrap{
        margin: 15px 6px 0;
    }
    .hm-ban-content .ban-left-text h3 {
        font-size: 18px;
    }
    .hm-right-ban {
        margin-top: 10px;
    }
    .acc-table .rdt_Table .rdt_TableBody .rdt_TableCell#cell-1-undefined,
    .acc-table .rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="1"]{
        max-width: 85px;
        min-width: 85px;
    }
    .ban-right-btn{
        transform: translateY(0) translateX(0);
        width: 100%;
        text-align: center;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        position: relative;
    }
    .checkout-table table tbody tr td.prt-price {
        padding-right: 10px;
    }
    .checkout-table.checkout-order table tbody tr td.table-tol{
        margin: 8px 0 0;
    }
    
}

@media (max-width: 479px) {

    .cart-item {
        max-width: 100%;
    }
    .checkout-title {
        font-size: 26px;
    }
    .cart-innr {
        max-width: 300px;
        margin: 0 auto;
    }
    .pay-popup {
        max-width: 90vw;
    }
    .dt-flx > div {
        max-width: 100%;
    }
    
    .promo-serve-fieldset .form-field{
        width: 100%;
    }

    .checkout-table table tbody tr{
        padding-left: 55px;
        min-height: 90px;
        padding-bottom: 15px;
        padding-right: 15px;
        float: left;
    }
    .checkout-table table tbody tr td span.prt-img{
        width: 70px;
        height: 70px;
    }
    .checkout-table table tbody tr td .prt-img .prt-ovl{
        width: 50px;
        height: 50px;
    }
    .checkout-table table tbody tr.free_product_list td span.prt-img{
        left: 15px;
    }
    .header-tab-menu > a{
        font-size: 14px;
        padding: 8px 5px 8px;
        margin: 0 1.5px;
    }

    .cart-mob-chkout{
        margin: 0 8px 0 0px;
    }
    .cart-count{ padding-left: 2px;}
    .cart-total{ padding: 6px 0 0 5px; font-size: 13px;}
    .checkout-table table tbody tr.no-line.lin-btn td.chk-btn-holder .pay-btn.button {
        width: 100%;
        margin: 2px 0px 2px;
    }
    .checkout-table .chk-btn-holder.chk-btn-wrap .button {
        width: 100%;
    }
    .check-box label{
        font-size: 14px;
    }
    /** dev **/
    tr.free_product_list_wrap td tr.free_product_list{
        padding-left: 90px;
        float: left;
    }
    .checkout-table table tbody tr td.table-del{
        bottom: 19px;
        margin-top: 0px;
    }

    /*** Free product list ****/
    .free-product-list li .free-prod-id,
    .free-prod-list-pop .free-product-list li .free-prod-id {
        padding: 0 0 5px;
        min-width: auto;
        align-items: self-start;
    }
    .free-product-list li .free-prod-price{
        /* padding-left: 52px; */
        padding-bottom: 0px;
        min-width: 80px;
    }
    .free-prod-list-pop .free-product-list li .free-prod-price{
        padding-top: 1px;
    }
    .free-product-list li .free-prod-unit{
        padding-bottom: 0px;
        min-width: 80px;
    }

    /*** home banner ***/
    .home-banner {
        margin: 30px 0px 0;
        display: flex;
        justify-content: center;
    }
    .home-banner .ban-wrap{
        /*max-width: 300px;*/
    }
    .home-ban-even-wrap .home-banner {
        margin: 30px 0px 0;
    }
    .ban-left-text {
        padding: 10px;
        width: 100%;
    }
    .ban-left-text::after{
        display: none;
    }
    .ban-right {
        width: 100%;
        padding: 0px 10px 10px;
        background: transparent;
    }
    .ban-right-btn a.button{
        margin: 10px 0 0;
        font-size: 13px;
    }
    .ban-right .ban-right-text p span {
        display: initial;
    }

    .hm-ban-content .ban-right-text {
        width: 90%;
    }

}

@media (max-width: 419px) {
    .cookies-on header {
        padding-top: 90px;
    }
    
}

@media (max-width: 370px) {

    .prot-itme {
        max-width: 100%;
    }
    .hm-left-ban {
        height: 180px;
    }
    .hm-right-ban {
        height: 140px;
    }
    .hm-ban-content .ban-right-text {
        width: 100%;
    }

}

@media only screen {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: inner-spin-button;
        /***
         * Below tried and failed: 
         ***/
        /* -moz-appearance: number-input; */ 
        /*-ms-appearance: inner-spin-button;*/
        /*appearance: auto;*/
        margin: 0;
        opacity: 1;
    }
    
    #updQtyLoose47 {
     /* basic styling */
    /* background: rgba(245, 235, 170, 0.75); */
    border-radius: 0;
    border: none;
    border: solid 1px #dbdbdb;
    color: inherit;
    display: block;
    outline: 0;
    padding: 3px 6px 3px;
    text-decoration: none;
    width: 65px;
    font-size: 14px;
    }
}

@supports (-webkit-touch-callout: none) {
    main.main-section {
        padding-bottom: 20px;
    }
}