@font-face {
    font-family: 'Proxima-Nova-Black';
    src: url('../fonts/ProximaNova-Black.woff2') format('woff2'),
        url('../fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima-Nova-Ex-Bold';
    src: url('../fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('../fonts/ProximaNova-Extrabld.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima-Nova-Bold';
    src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

:root {

    --fontSize22: 22px;
    --fontSize24: 24px;
    --fontSize28: 28px;
    --fontSize32: 32px
}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

html {
    /* overflow-x: hidden; */
    width: 100%;
}

body {
    position: relative;
    font-family: 'Proxima Nova Rg';
    min-height: 100vh;
    line-height: 1;
}

body::after {
    content: "";
    display: block;
    height: var(--padBottom);
}

ol,
ul {
    list-style: none;
}

a {
    text-decoration: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 5px;
    height: 40px;
    font-size: 15px;
    color: #555555;
    font-family: 'Proxima Nova Rg';
}

textarea {
    height: 75px;
    resize: none;
}

.checkbox {
    width: 30px !important;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    margin: 15px 0px 10px 0px;
    padding: 12px 24px 12px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #ec2f45;
    border: 0px;
    font-family: 'Proxima Nova Rg';
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
    box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.btn:hover,
.button:hover,
button:hover {
    color: #fff;
    background: #070802;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000000;
    /*font-weight: normal;*/
    margin: 0 0 15px;
}

a {
    cursor: pointer;
    color: #332e20;
}

a:hover {
    color: #ec2f45;
}

strong {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

/* Code -- START */

.container {
    max-width: 1180px;
    margin: 0 auto;
}

.sm-container {
    max-width: 1080px;
    margin: 0 auto;
}

/* Cart List -- START */
.cart-block {
    padding: 50px 0 30px;
}

.cart-flx {
    display: flex;
    flex-flow: row wrap;
}

.cart-item {
    flex: 1 1 auto;
    max-width: 25%;
}

.cart-innr {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #332e20;
    overflow: hidden;
    box-shadow: -1px 2px 8px -1px rgb(51 46 32 / 0%);
    margin: 0 7px;
    transition: all .5s ease-in-out;
}

.cart-innr:hover {
    box-shadow: -1px 2px 8px -1px rgb(51 46 32 / 40%);
    transition: all .5s ease-in-out;
}

.cart-innr::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, #fff 0% 10%, transparent 50% 100%);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.cart-innr>div {
    position: absolute;
    z-index: 2;
}

.cart-top {
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.cart-top img {
    max-width: 235px;
    margin: 20px 2px 0px;
}

.cart-top p {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #020202;
    margin: 20px 15px 0px;
    text-align: center;
    text-transform: uppercase;
}

.cart-bottom {
    bottom: 0;
    padding: 20px;
    width: 100%;
    background: rgb(51 46 32 /25%);
    transition: all .5s ease-in-out;
    -webkit-transform: translateY(110%);
    transform: translateY(110%);
}

.cart-innr:hover .cart-bottom {
    background: rgb(51 46 32 /50%);
    transition: all .5s ease-in-out;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.cart-bottom p {
    font-size: 16px;
    line-height: 1.1;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}

.cart-back {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart-flx .cart-item {
    min-height: 200px;
}

/* Cart List -- END */

/* Checkout -- START */

.checkout-block {
    margin: 35px 0 15px;
    padding: 0 10px;
}

.checkout-title,
.page-title {
    font-size: 36px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
    margin: 0 0 25px;
}

.checkout-title {
    text-transform: uppercase;
}

.checkout-header .page-title {
    margin: 0 0 20px;
}

.acc-ttflx {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px;
}

.acc-ttflx .checkout-title {
    margin: 0 0 0;
}

.acc-ttflx .checkout-title:empty {
    display: none;
}

.acc-ttflx .acc-back {
    display: flex;
    align-items: center;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #332e20;
}

.acc-ttflx .acc-back:hover {
    color: #ec2f45;
}

.acc-ttflx .acc-back .fa {
    font-size: 11px;
    font-weight: 400;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-54%);
}

.checkout-table.loading-buffer {
    position: relative;
    min-height: 180px;
}

.checkout-table .table_overflow {
    margin-bottom: 0px;
}

.checkout-table table thead tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.checkout-table table thead tr td {
    font-size: 17px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.2;
    padding: 0 5px 0 0;
    color: #2B2E34;
}

.checkout-table table tr td.prt-50 {
    width: 50px;
    text-align: center;
}

.checkout-table table tr td.prt-70 {
    width: 50px;
    text-align: center;
}


.checkout-table table tbody tr td.prt-title {
    width: 300px;
}

.checkout-table table tbody tr td.info-sec {
    text-align: center;
}

.checkout-table.checkout-order table tbody tr td.info-sec,
.checkout-table.checkout-order table thead tr td:nth-child(3) {
    width: 65px;
}

.checkout-table table tbody tr td.prt-50 {
    text-align: center;
}

.checkout-table table tr td .prt-info {
    display: inline-block;
    cursor: pointer;
}

.checkout-table table tr td .prt-info img {
    width: 18px;
}

.prt-info .nrm {
    display: block;
}

.prt-info .hov {
    display: none;
}

.prt-info:hover .hov {
    display: block;
}

.prt-info:hover .nrm {
    display: none;
}

.checkout-table table tbody tr td .prt-img .prt-ovl {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 5px;
}

.checkout-table table tbody tr td .prt-img .prt-ovl::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: rgb(0 0 0 / 0%);
    transition: all .5s ease-in-out;
}

.checkout-table table tbody tr td .prt-img:hover .prt-ovl::before {
    background: rgb(0 0 0 / 2%);
}

.checkout-table table tbody tr td .prt-img img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.checkout-table table thead tr td {
    width: 140px;
    padding: 10px 10px 10px 0px;
    background: #f6f6f6;
}

.checkout-table table thead tr td.table-qty {
    width: 120px;
}

.checkout-table table thead tr td:last-child {
    width: 40px;
}

.checkout-table table tbody {}

.checkout-table table thead.ord-grand-total tr td:last-child {
    width: 120px;
}

.checkout-table table tbody tr {
    border-bottom: 1px solid #dfd9d9;
}

.checkout-table table tbody tr td {
    padding: 0 0 5px;
}

.checkout-table table tbody tr.no-line {
    border-bottom: 0px;
}

.checkout-table table tbody tr.no-line.lin-btn td {
    vertical-align: bottom;
}

.checkout-table table tbody tr.no-line.lin-btn td.vrt-mid {
    vertical-align: middle;
    width: 150px;
    text-align: center;
    padding: 15px 0 15px;
}

.checkout-table table tbody tr td:first-child {}

.checkout-table table tbody tr td:last-child {
    width: 130px;
}

.checkout-table table tbody tr td.add-btn-fld {
    width: auto;
}

.checkout-table table tbody tr td:first-child {
    width: -moz-fit-content;
    width: fit-content;
}

.checkout-table table tbody tr td {
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-size: 15px;
    color: #2B2E34;
    padding: 10px 5px 10px 0px;
    vertical-align: middle;
}

.acc-block .checkout-table table tbody tr td {
    padding: 10px 10px 10px 10px;
}

.checkout-table table tbody tr td.prt-title .prtf-flx {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 220px;
}

.checkout-table table tbody tr td .add-Cart-btn-wrap {
    width: 95px;
}

.checkout-table table tbody tr td span.prt-img {
    position: relative;
    left: 0;
    top: auto;
}

.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt {
    padding: 2px 12px 5px 5px;
    text-align: left;
    font-size: 15px;
}

.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt h4 {
    margin: 0 0 4px;
}

.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt:hover,
.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt h4:hover {
    cursor: pointer;
    color: #ec2f45;
}

.checkout-table table tbody tr td.prt-title .prtf-flx.prtf-point,
.checkout-table table tbody tr td.prt-title:hover .prtf-flx.prtf-point {
    pointer-events: none;
}

.checkout-table table tbody tr td.prt-title:hover .prtf-flx.prtf-point .prt-txt {
    cursor: none;
    color: #000;
}

.checkout-table.checkout-order table tbody tr td.td-free-del {
    color: #db4758;
    padding: 0;
}

td.td-vat-fee span.vat-amt {
    display: inline-block;
}

td.td-vat-fee span.color-red {
    display: inline-block;
}

.checkout-table table tbody tr td.td-vat-fee span.colon-bold {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 18px;
    display: inline-block;
}

.order-pop td.td-vat-fee span.vat-txt {
    font-size: 15px;
}

.checkout-table.checkout-order table tbody tr td.prt-title .prtf-flx {
    width: 300px;
}

.checkout-table.checkout-order tbody.checkout_qty_loader {
    position: relative;
}

.checkout-table.checkout-order tbody.checkout_qty_loader::before {
    position: fixed;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(245, 245, 245, 0.85);
    z-index: 9;
}

.checkout-table.checkout-order tbody.checkout_qty_loader::after {
    position: fixed;
    content: '';
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    border: 3px solid #ec2f45;
    border-top: 3px solid transparent;
    border-bottom: 3px solid #ec2f45;
    border-radius: 50%;
    /*background: url("../images/wood-oven.gif") no-repeat center;
    background-size: 120px;*/
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 10;
}

.checkout-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.back-to-cat {
    margin: 0 0 15px;
}

.back-to-cat a.back-btn,
.acc-ttflx a.acc-back {
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 5px 12px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    padding-left: 25px;
    position: relative;
    display: inline-block;
    line-height: 1.5;
}

.back-to-cat a.back-btn:hover,
.acc-ttflx .acc-back:hover {
    border-color: #ec2f45;
}

.back-to-cat a.back-btn i {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.prt-ico-flx {
    position: relative;
    display: flex;
    padding-top: 7px;
    margin-top: 7px;
}

.prt-ico-flx:empty {
    display: none;
}

.prt-ico-flx::after {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 1px;
    background: #ddd;
}

.checkout-table table tbody tr td .prt-ico {
    margin-left: 5px;
}

.checkout-table table tbody tr td .prt-ico:first-child {
    margin-left: 0px;
}

.checkout-table table tbody tr td.table-tol {
    width: 100px;
    text-align: center;
}

.checkout-table table tbody tr td.prt-price,
.checkout-table table tbody tr td.prt-tal {
    /*padding-right: 15px;*/
    text-align: right;
}

.ico-del {
    cursor: pointer;
}

.ico-del .fa {
    color: #000;
}

.ico-del:hover .fa {
    color: #d30000;
}

.checkout-table table tbody tr td.table-del {
    width: 40px;
}

.checkout-table table tbody td.table-qty {
    width: 105px;
}

.checkout-table table tbody tr td.table-qty.prt-qty {
    flex-wrap: wrap;
    padding: 10px 0 10px 10px;
}

.checkout-table table tbody tr td.prt-qty input[type="text"] {
    width: 38px;
    height: 30px;
    padding: 5px;
    border: 1px solid #d4d4d4;
    border-radius: 0px;
    text-align: center;
    margin: 0;
}

.checkout-table table tbody tr td .qty-action-btn {
    width: 26px;
    height: 30px;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    background: #070802;
    color: #FFF;
    line-height: 30px;
    cursor: pointer;
}

.prt-qty-wrap {
    display: flex;
}

.checkout-table table tbody tr td .qty-action-btn:hover {
    background: #ec2f45;
}

.checkout-table table tbody td .prot-flx {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
}

.checkout-table table tbody td .prot-info {
    display: block;
    flex: 1 1 auto;
    max-width: calc(100% - 35px);
}

.checkout-table table tbody tr td .close-product {
    position: relative;
    display: block;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    opacity: 1;
    margin-top: 2px;
    margin-right: 8px;
    cursor: pointer;
}

.checkout-table table tbody tr td .close-product::before,
.checkout-table table tbody tr td .close-product::after {
    content: "";
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    width: 1.5px;
    background-color: #000;
}

.checkout-table table tbody tr td .close-product:hover::before,
.checkout-table table tbody tr td .close-product:hover::after {
    background-color: rgb(255, 51, 0);
}

.checkout-table table tbody tr td .close-product::before {
    transform: rotate(45deg);
}

.checkout-table table tbody tr td .close-product::after {
    transform: rotate(-45deg);
}

.checkout-table table tbody tr td .form-control {
    position: relative;
    font-size: 18px;
    color: #2B2E34;
    width: 50px;
    border: 0px;
    outline: 0px;
    z-index: 19;
}

.checkout-table table tbody tr td .form-control::-webkit-inner-spin-button,
.checkout-table table tbody tr td .form-control::-webkit-outer-spin-button {
    opacity: 1;
    background-color: transparent;
    background: transparent;
    color: #2B2E34;
}

.checkout-table table tbody tr td span {
    display: block;
}

.free-category-title {
    color: #2B2E34;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding-top: 2px;
}

.checkout-table table tbody tr td span.strong {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #020202;
}

.checkout-table table tbody tr td span.vat-txt {
    font-size: 15px;
    display: inline-block;
}

.checkout-table table tbody tr td span.unit-sale-txt {
    width: 80px;
    text-align: left;
}

.checkout-table table tbody tr td.td-free-del span.strong {
    color: #020202;
}

.checkout-table table tbody tr.total td {
    padding: 10px 0;
    text-align: right;
}

.checkout-table table tbody tr.total td.table-tol.prt-tal {
    padding-right: 15px;
}

.checkout-table table tbody tr td .button {
    display: block;
    width: calc(100% - 5px);
    margin: 0px auto 0;
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 8px 6px;
    text-transform: capitalize;
    border-radius: 4px;
    box-shadow: none;
    min-width: 160px;
}

.checkout-table table tbody tr td .button.back-btn,
.back-to-main .button.back-btn {
    color: #000000;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-family: 'Proxima Nova Rg';
    background: transparent;
    width: 100%;
    padding: 0px 2px;
    text-align: left;
}

.back-to-main .button.back-btn {
    padding: 6px 12px;
    text-align: initial;
    font-family: 'Proxima Nova Rg';
    width: auto;
    display: inline-block;
    border: 1px solid #e5e5e5;
    box-shadow: none;
}

.checkout-table table tbody tr td .button.back-btn,
.checkout-table table tbody tr td .button.pay-btn {
    margin: 0;
}

.checkout-table table tbody tr td .button.back-btn {
    margin: 10px 0 0;
}

.checkout-table table tbody tr td .button.back-btn:hover,
.back-to-main .button.back-btn:hover {
    color: #ec2f45;
}

.checkout-table table tbody tr.total td {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-variant-numeric: tabular-nums;
}

.back-to-main {
    text-align: center;
    padding: 0 0 20px;
}

button.add-cart-btn {
    padding: 3px 7px;
    margin: 0;
    background: #070802;
    box-shadow: none;
    border-radius: 4px;
}

button.add-cart-btn:hover {
    background: #ec2f45;
}

.add-btn-fld button.add-cart-btn {
    font-size: 12px;
    padding: 8px 8px 7px;
    min-width: 94px;
}

button.add-cart-btn.addedToCart {
    background: #ec2f45;
}

.checkout-table table tbody tr td span.txt-del {
    display: inline-block;
    font-size: 13px;
    padding: 5px 7px 4px;
    margin: 0;
    background: #070802;
    color: #FFF;
    box-shadow: none;
    border-radius: 4px;
}

.checkout-table table tbody tr td span.txt-del:hover {
    background: #ec2f45;
}

.checkout-table table tbody tr.no-line.lin-btn td.vrt-mid.no_product_found {
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    background: #fff;
}

.no_product_found .no_product_txt {
    font-size: 20px;
    padding: 30px 90px 200px;
    font-family: 'Proxima Nova Rg';
    color: #959595;
    background: url(../images/no-file-found.png) no-repeat center bottom;
    background-size: 180px;
}

.no_product_found .no_product_txt a {
    font-family: 'Proxima-Nova-Bold';
    color: #959595;
}

.no_product_found .no_product_txt a:hover {
    color: #ec2f45;
}

.no_product_found .no_product_txt span {
    font-size: 2.75em;
    display: block;
    padding: 0 0 10px;
    font-family: 'Proxima-Nova-Bold';
}

.checkout-table td.no_product_found {

    background: #fff !important;
    height: 200px;

}

.checkout-table table tbody tr.no-line.lin-btn td.chk-btn-holder {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.checkout-table table tbody tr.no-line.lin-btn td.chk-btn-holder .pay-btn.button {
    margin: 2px 6px 2px;
}

.checkout-table .chk-btn-holder.chk-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0px 15px 0;
}

.checkout-table .chk-btn-holder.chk-btn-wrap .button {
    margin: 6px 6px 4px;
    padding: 12px 22px 12px;
}

/* Checkout -- END */

/** checkout cart New  -- Start **/
.checkout-cart-wrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.checkout-cart-wrap .checkout-table {
    width: 60%;
}

.cart-side-table {
    width: 40%;
    padding-left: 30px;
    position: sticky;
    top: 20px;
    max-height: calc(100vh - 40px);
    margin-bottom: 20px;
    z-index: 100;
}

.cart-side-table .cart-table table thead tr {
    border-bottom: 1px solid #959699;
}

.cart-side-table .cart-table table tbody tr {
    display: block;
    width: 100%;
}

.cart-side-table .cart-table table thead tr td {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.2;
    color: #2B2E34;
    padding: 10px 10px 10px 10px;
    background: #f6f6f6;
}

.cart-side-table .cart-table table tbody tr td {
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-size: 15px;
    color: #2B2E34;
    padding: 10px 5px 10px 0px;
    vertical-align: middle;
}

.cart-side-table .cart-table table thead tr td span {
    font-size: 15px;
    font-weight: 400;
}

.cart-side-table .cart-table {
    box-shadow: 0px 6px 8px 0px rgb(40, 20, 40, 0.08);
    height: 100%;
    /* overflow-y: auto; */
}

.cart-side-table .cart-table::-webkit-scrollbar {
    width: 6px;
}

.cart-side-table .cart-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.cart-side-table .cart-table::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
}

.cart-side-table .cart-table table tbody {
    padding: 15px 15px 12px;
    display: block;
}

.cart-side-table .cart-table table tbody tr td input[type="number"] {
    border-radius: 0;
    border: none;
    border: solid 1px #dbdbdb;
    color: inherit;
    display: block;
    outline: 0;
    padding: 3px 6px 3px;
    text-decoration: none;
    width: 65px;
    font-size: 14px;
}

.cart-side-table .cart-table table tbody tr td.chkout-btn {
    width: 100%;
    display: block;
}

.cart-side-table .cart-table table tbody tr td .button {
    display: block;
    width: calc(100% - 5px);
    margin: 0px auto 0;
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 8px 6px;
    text-transform: uppercase;
    border-radius: 0px;
    box-shadow: none;
    min-width: 160px;
}

.cart-table-header {
    padding: 10px 10px;
    background: #f6f6f6;
    border-bottom: 1px solid #959699;
}

.cart-table-header h4 {
    margin: 0;
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.cart-table-header h4 span {
    font-size: 16px;
    font-weight: 400;
}

.cart-table-header h4 .clear-cart,
.clear-cart-wrap .clear-cart {
    float: right;
    display: inline-block;
    padding: 6px 10px;
    background: #222;
    color: #FFF;
    margin-top: -1px;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
}

.cart-table-header h4 .clear-cart:hover,
.clear-cart-wrap .clear-cart:hover {
    background: #ec2f45;
}

.clear-cart-wrap {
    display: none;
}

.cart-mobile-header {
    display: none;
}

.cart-table-body {
    padding: 10px 10px 20px;
    margin: 0;
    height: calc(100% - 80px);
    min-height: 300px;
    position: relative;
}

.cart-table-body ul.main-cat-cart {
    padding: 0 0 12px;
    width: 100%;
    max-height: calc(100% - 140px);
    overflow-y: auto;
    min-height: 30px;
    padding-right: 2px;
}

@-moz-document url-prefix() {
    .cart-table-body ul.main-cat-cart {
        scrollbar-width: thin;
        scrollbar-color: #d6d6d6 #eaeaea;
    }
}

.cart-table-body ul::-webkit-scrollbar {
    width: 5px;
    position: absolute;
}

.cart-table-body ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.cart-table-body ul::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
}

.cart-table-body>ul.main-cat-cart li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    padding: 12px 0;
    padding-right: 22px;
    border-bottom: 1px solid #efefef;
}

.cart-table-body>ul>li:first-child {}

.cart-table-body>ul>li:last-child {
    border: none;
}

.cart-table-body>ul.main-cat-cart li.main-title-list {
    background: #ebe8e1;
    padding: 8px 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #332e20;
}

.cart-table-body>ul.main-cat-cart ul.sub-cat-cart li.sub-title-list {
    padding: 6px 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 15px;
}

.cart-table-body>ul.main-cat-cart li .cart-prod-delete {
    position: absolute;
    padding: 4px;
    top: 18px;
    right: 4px;
    background: #222;
    color: #FFF;
    line-height: 0.6;
    font-size: 12px;
    border-radius: 2px;
    cursor: pointer;
}

.cart-table-body>ul.main-cat-cart li .cart-prod-delete:hover {
    background: #ec2f45;
}

.cart-table-body>ul.main-cat-cart li .cart-prod-id {
    width: calc(100% - 145px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.cart-table-body>ul.loading-buffer {
    pointer-events: none;
    z-index: 2;
}

.loading-buffer::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(246, 246, 246, 0.85);
    z-index: 2;
}

.loading-buffer::after {
    position: absolute;
    content: '';
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    border: 3px solid #ec2f45;
    border-radius: 50%;
    border-top: 3px solid transparent;
    border-bottom: 3px solid #ec2f45;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 3;
}

.no_product_found.loading-buffer {
    min-height: 200px;
}

.main-cat-cart.loading-buffer::before {
    height: calc(100% + 40px);
}

.main-cat-cart.loading-buffer::after {
    top: calc(50% - 80px);
}

.checkout-table .loading-buffer:after {
    top: calc(50% - 10px);
}

.cart-prod-id span.prod-img-samp {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 0px;
    overflow: hidden;
}

.cart-prod-id span.prod-img-samp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart-prod-id .prod-title-txt {
    padding: 1px 10px 0 6px;
    font-size: 15px;
    line-height: 1.2;
    width: calc(100% - 30px);
}

.cart-prod-id .prod-title-txt i.fa-th-list {
    font-size: 14px;
}

.cart-prod-id .prod-title-txt span.color-red {
    font-size: 1.15em;
}

.cart-table-body>ul.main-cat-cart li .cart-prod-qty,
.cart-prod-qty {
    width: 70px;
    position: relative;
    padding-right: 10px;
    display: flex;
}

.cart-prod-qty input.qty-number {
    border-radius: 0;
    border: solid 1px #dbdbdb;
    height: 26px;
    display: block;
    outline: 0;
    margin: 0;
    padding: 3px 12px 3px;
    text-decoration: none;
    font-size: 14px;
    text-align: center;
}

.cart-prod-qty .free_edit_btn {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 13px;
    margin-top: 2px;
    padding: 4px;
    cursor: pointer;
    color: #ec2f45;
}

.cart-prod-qty .free_edit_btn:hover {
    color: #ec2f45;
}

.cart-prod-qty .qty-val-action {
    position: absolute;
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 22px;
    line-height: 24px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    text-align: center;
}

.cart-prod-qty .qty-val-action:hover {
    color: #ec2f45;
}

.cart-prod-qty .qty-val-minus {
    left: 2px;
    top: 2px;
    line-height: 21px;
}

.cart-prod-qty .qty-val-plus {
    right: 13px;
    left: auto;
}

.cart-table-body>ul.main-cat-cart li .cart-prod-total {
    width: 75px;
    padding: 5px 4px 0 0px;
    text-align: right;
}

.cart-table-body>ul.main-cat-cart li.free_product_list .cart-prod-qty .qty-val-action {
    visibility: hidden;
}

.cart-prod-amt {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 13px;
    line-height: 1.4;
}

.cart-table-bottom {
    margin: 15px 0 0px;
    padding: 8px 0 0px;
    border-top: 1px solid #dbd9d9d9;
    position: relative;
    z-index: 3;
}

.cart-all-prod-total {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 32px 4px 10px;
}

.cart-all-prod-total h5 {
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0;
}

.color-red {
    color: #ec2f45 !important;
}

.text-center {
    text-align: center;
}

.pl-15 {
    padding-left: 15px;
}

.cart-prod-total-val {
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-variant-numeric: tabular-nums;
}

.cart-checkout-button {
    padding: 10px 0 0;
}

.cart-checkout-button a.checkout-btn {
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0;
}

/** checkout cart New  -- End **/

/* Checkout terms */
.agree-terms {
    width: 100%;
    text-align: center;
    padding: 15px 0 10px;
}

.agree-terms a {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.check-box {
    display: block;
    margin-bottom: 15px;
}

.check-box input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.check-box label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    display: inline-block;
    text-align: left;
}

.check-box label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ec2f45;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%), inset 0px -15px 10px -12px rgb(0 0 0 / 10%);
    padding: 8.6px;
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    top: 0px;
    left: 0;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
}

.check-box input:checked+label:before {
    border-color: #ec2f45;
}

.check-box input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 8px;
    width: 6px;
    height: 10px;
    border: solid #ec2f45;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/*  Checkout terms Ends */

/* Checkout client details */
.client-details {
    width: 100%;
    padding: 18px 20px 8px;
    background: #f6f6f6;
    border-radius: 8px;
    margin: 10px 0 20px;
}

.client-details h3 {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    margin: 0 0 10px;
}

.client-detail-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -10px;
}

.client-detail-form .form-field {
    width: calc(33.33% - 20px);
    margin: 5px 10px 5px;
}

.client-detail-form .form-field.field-text-area {
    width: 100%;
}

/** cart product slider **/
.prod-slider-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.prod-slider-list .prod-slider {
    display: block;
    padding: 20px 10px;
    background: #fbfbfb;
    border-radius: 10px;
    margin: 0 0 20px;
    width: 100%;
    max-width: 900px;
}

.prod-slider-list .prod-slider .prod-slides {
    position: relative;
    padding: 0 10px;
}

.prod-slider .prod-slides .prod-slide-img {
    width: 230px;
    width: 100%;
    height: 130px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.prod-slider .prod-slider-active .prod-slide-img {
    border: 3px solid #ec2f45;
}

.prod-slide-img::after {
    content: "";
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0% 30%, transparent 70% 100%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.prod-slider .prod-slides .prod-slide-name {
    width: 230px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 15px;
    z-index: 1;
}

.prod-slider .prod-slides .prod-slide-name p {
    color: #FFF;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 18px;
}

.checkout-table table tbody tr td.add-btn-fld::before {
    display: none;
}

.prod-slider-list .prod-slider .slick-next,
.prod-slider-list .prod-slider .slick-prev {
    margin: 0;
    background: transparent;
    box-shadow: none;
    width: 32px;
    height: 32px;
}

.prod-slider-list .prod-slider .slick-next:before,
.prod-slider-list .prod-slider .slick-prev:before {
    color: #b4b4b4;
    font-size: 22px;
}

.prod-slider-list .prod-slider .slick-next:hover:before,
.prod-slider-list .prod-slider .slick-prev:hover:before {
    color: #ec2f45;
}

/* Catergory & Product List -- START */
.cater-block {
    margin: 55px 0;
}

.cater-sec {}

.cater-sec h1 {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    line-height: 1.3;
    color: #ec2f45;
    margin: 0 0 50px;
}

.cater-flx {
    display: flex;
    flex-flow: row wrap;
}

.cater-item {
    flex: 1 1 auto;
    max-width: 50%;
    width: 100%;
    text-align: center;
    padding: 7px;
}

.cater-innr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: var(--fontSize28);
    line-height: 1.3;
    background: rgb(236 47 69 / 10%);
    color: #ec2f45;
    padding: 5px;
    min-height: 160px;
    box-shadow: 1px 2px 4px 1px rgb(0 0 0 / 0%);
    transition: all .5s ease-in-out;
}

.cater-innr:hover {
    box-shadow: 1px 2px 4px 1px rgb(0 0 0 / 15%);
    background: rgb(236 47 69 / 90%);
    color: #FFF;
}

.cater-prot-flx {
    display: flex;
    flex-flow: row wrap;
}

.prot-itme {
    flex: 1 1 auto;
    max-width: 25%;
    width: 100%;
    text-align: center;
}

.prot-innr {
    display: block;
    margin: 0 10px 40px;
}

.prot-ico {}

.prot-ico img {}

.prot-innr span {
    display: block;
    font-size: 18px;
    color: #ec2f45;
    max-width: 195px;
    margin: 15px auto 0;
}

/* Catergory & Product List --- END */

/* Extra Popup -- START */

.info-poup {}

.info-bdy {}

.info-poup .order-top h4 span {
    position: relative;
    padding-left: 25px;
    padding-right: 30px;
    display: block;
}

.info-poup .order-top h4 img {
    position: absolute;
    width: 22px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.info-item {
    position: relative;
    text-align: left;
    padding: 0 0 15px;
    border-bottom: 1.8px solid #D9D9D9;
    margin: 0 0 15px;
}

.info-item:last-child {
    padding: 0 0 0;
    border-bottom: 0px;
    margin: 0 0 0;
}

.info-item .info-innr>h5 {
    margin: 0 0 8px;
    color: #000;
}

.info-innr h5 span {
    position: relative;
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
    padding-right: 7px;
}

.info-innr h5 span::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.info-innr p {
    font-size: 16px;
    line-height: 1.4;
}

.info-innr p span {
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
}

.info-innr p strong {
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
    text-transform: uppercase;
}

.info-innr p a {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #eb5466;
    line-height: 1;
    text-decoration: underline;
}

.info-innr p a:hover {
    color: #ec2f45;
}

.modal.prt-poup .close {
    background: #fff;
    opacity: 1;
    top: 5px;
    right: 5px;
    border-bottom-left-radius: 18px;
}

.modal.prt-poup .close:hover::before,
.modal.prt-poup .close:hover::after {
    background-color: #f00;
}

.modal.prt-poup.prt-img-poup .close {
    background: transparent;
    top: 15px;
    right: 10px;
}

.modal.prt-poup.prt-img-poup .prt-dsc {
    margin: 0;
}

.modal.prt-poup.prt-img-poup .order-top h4 {
    font-size: var(--fontSize22);
}

.prt-bdy-img {
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
}

.prt-bdy-img img {
    max-width: 100%;
}

.prt-dsc {
    display: flex;
    justify-content: center;
    margin: 10px 0 2px;
}

.prt-dsc .pop-prt-txt {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    color: #000;
    padding: 0px 8px;
    border-radius: 2px;
}

.prt-dsc .pop-prt-txt:empty {
    display: none;
}

/* Extra Popup -- END */

/* ThankYou Page -- START */

.thank-section {
    margin: 65px 0;
}

.thank-section .sm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
}

.thank-block {
    position: relative;
    text-align: center;
    width: 100%;
    background: #fff;
    border: 1px solid #eef2e2;
    box-shadow: 1px 2px 12px 3px rgb(0 0 0 / 15%);
    border-radius: 5px;
}

.thank-head {
    padding: 25px 10px 15px;
    background: #eef2e2;
}

.thank-head h3 {
    font-size: var(--fontSize24);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    margin: 5px 0 5px;
}

.thank-block p {
    line-height: 1.5;
}

.thank-details-title p {
    font-size: 18px;
}

.thank-ico {
    margin: 0 0 15px;
}

.thank-ico .fa {
    font-size: 50px;
    color: #9c0;
}

.thank-body {
    padding: 25px 10px;
}

.thank-block h1 {
    font-size: var(--fontSize28);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    line-height: 1.3;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.thank-btn {
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    box-shadow: none;
    border-radius: 0px;
    text-transform: capitalize;
    padding: 10px 15px;
    margin: 10px 0 0;
}

/* ThankYou Page -- END */

/* Order Detail Popup -- START */
.order-pop.modal .close {
    top: 10px;
    right: 10px;
    opacity: 1;
}

.order-pop.modal .close:hover::before,
.order-pop.modal .close:hover::after {
    background-color: #f90000;
}

.order-top {
    text-align: center;
}

.pop-header-top.order-top {
    position: sticky;
    top: -1px;
    z-index: 9;
}

.order-top .head {
    padding: 10px 15px;
    background: #f2f4df;
    text-align: center;
}

.order-top h4 {
    font-size: var(--fontSize22);
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
    margin: 0 0 0;
}

.order-top .bottom {
    padding: 20px 40px;
    background: #ec2f45;
    text-align: center;
}

.order-top .bottom h3 {
    font-size: var(--fontSize22);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    color: #fff;
}

.order-top .bottom p {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #fff;
    margin: 0 0 10px;
}

.order-top .bottom p span.pay,
.order-top .bottom p span.date {
    font-weight: 400;
    padding-left: 5px;
    font-family: 'Proxima Nova Rg';
}

.order-top .bottom p:last-child {
    margin-bottom: 0;
}

.order-status {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 25px 0 35px;
    z-index: 1;
}

.order-status::after {
    content: "";
    position: absolute;
    top: 15px;
    display: block;
    width: 100%;
    height: 2px;
    border-bottom: 1px dashed #9c0;
    z-index: -1;
}

.order-han {
    flex: 1 1 auto;
    max-width: 50%;
}

.order-han.left {
    text-align: left;
}

.order-han.right {
    text-align: right;
}

.order-innr {}

.order-innr h6 {
    font-size: var(--fontSize22);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 5px;
}

.order-innr p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
}

.order-innr h3 {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 15px 0 0;
}

.order-innr h3 span {
    display: block;
    font-size: var(--fontSize22);
    margin: 0 0 5px;
}

.order-pop .order-top .bottom {
    background: #FFF;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: space-between;
}

.order-pop .order-top .bottom h3,
.order-pop .order-top .bottom h4,
.order-pop .order-top .bottom p {
    color: #332e20;
}

.order_no_details {
    width: 40%;
    max-width: 400px;
}

.order_account_details {
    width: 40%;
    max-width: 400px;
}

.order_no_details p,
.order_account_details p {
    display: block;
}

.order_no_details p span.ord-det-txt,
.order_account_details p span.ord-det-txt {
    width: auto;
}

.order_no_details p span.pay,
.order_account_details p span.pay,
.order_no_details p span.date,
.order_account_details p span.date {
    width: auto;
}

/** order pagination **/
.rdt_Pagination {
    padding: 5px 0 0;
    margin: 0px 0 5px;
}

.rdt_Pagination button[type="button"] {
    background: #f2f4df;
    fill: #ec2f45;
    margin: 0 4px;
}

.rdt_Pagination button[type="button"]:hover,
.rdt_Pagination button[type="button"]:active {
    background: #ec2f45;
    fill: #f2f4df;
}

.rdt_Pagination button[type="button"]:disabled {
    background: #ccc;
    fill: #969696;
}

.rdt_Pagination select,
.rdt_Pagination select.jaZsat {
    padding: 5px 20px 5px 10px;
    margin: 0;
    border: 1px solid #b4b4b4;
    width: 30px;
}

.rdt_Pagination select.jaZsat+svg {
    top: 5px;
    right: 5px;
}

.rdt_Pagination select option:empty {
    display: none;
}

.table_overflow .rdt_Pagination select+svg {
    top: 49%;
    transform: translateY(-50%);
    right: 2px;
}

/** Order list **/
.rdt_Table .rdt_TableHead .rdt_TableCol {
    font-size: 1.275em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.rdt_Table .rdt_TableHead .rdt_TableCol_Sortable>div,
.rdt_Table .rdt_TableBody .rdt_TableCell>div,
.rdt_Table .rdt_TableBody .rdt_TableCell.dtqzBx div:first-child {
    white-space: normal;
}

.rdt_Table .rdt_TableBody .rdt_TableCell {
    font-size: 1.125em;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a {
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="2"] {
    min-width: 130px;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="4"],
.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="5"] {
    min-width: 140px;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="6"] {
    min-width: 100px;
    max-width: 100px;
}

.rdt_Table .rdt_TableBody #cell-2-undefined.rdt_TableCell,
.rdt_Table .rdt_TableBody #cell-6-undefined.rdt_TableCell,
.rdt_Table .rdt_TableBody #cell-7-undefined.rdt_TableCell {
    min-width: 130px;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="3"] {
    min-width: 130px;
}

.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="3"],
.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="3"] {
    min-width: 130px;
}

.rdt_Table .rdt_TableBody #cell-5-undefined.rdt_TableCell,
.rdt_Table .rdt_TableBody #cell-4-undefined.rdt_TableCell {
    min-width: 140px;
    font-size: 1.075em;
}

.rdt_Table .rdt_TableBody #cell-6-undefined.rdt_TableCell {
    min-width: 120px;
}

.rdt_Table .rdt_TableBody .rdt_TableCell[data-column-id="9"] {
    min-width: 40px;
    max-width: 40px;
}

.rdt_Table .rdt_TableBody .rdt_TableCell#cell-1-undefined,
.rdt_Table .rdt_TableHead .rdt_TableCol[data-column-id="1"] {
    position: sticky !important;
    left: 0px;
    z-index: 2;
    background: #fff;
    max-width: 100px;
    padding-left: 10px;
}

#cell-5-undefined {
    text-align: left;
}

#cell-6-undefined {
    text-align: left;
}

#cell-5-undefined>div,
#cell-6-undefined>div {
    width: 100%;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.view-btn {
    padding: 4px 10px 4px;
    border-radius: 5px;
    background: #222;
    color: #FFF;
    display: inline-block;
    font-size: 15px;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.re-Ord-btn {
    padding: 4px 10px 4px;
    border-radius: 5px;
    background: #ec2f45;
    color: #FFF;
    display: inline-block;
    font-size: 15px;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.view-btn:hover {
    background: #ec2f45;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.re-Ord-btn:hover {
    background: #222;
}

.print-buttons {
    padding: 10px 10px 10px;
    text-align: right;
}

.print-buttons a.button.print-Ord-btn {
    margin: 5px 5px;
    padding: 10px 20px 10px;
}

.rdt_Table .rdt_TableHeadRow,
.rdt_Table .rdt_TableRow {
    padding-left: 10px;
}

.rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(even) {
    background: #fbfbfb;
}

.acc-table .rdt_Table .rdt_TableHeadRow,
.acc-table .rdt_Table .rdt_TableRow {
    padding-left: 0px;
}

.forget-pass-field {
    padding: 15px 0 0;
    border-top: 1px solid #e5e5e5;
    margin: 10px 0 0;
}

.forget-pass-field a {
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #323232;
}

.forget-pass-field a:hover {
    color: #ec2f45;
}

.item-title {
    font-size: var(--fontSize22);
    padding: 7px 15px;
    background: rgba(51, 46, 32, 0.5);
    color: #fff;
}

.ico {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    background: transparent;
    margin: 0 0 10px;
}

.bike-ico {
    background-image: url(../images/motorcycle.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 50px 0 0;
    background-color: #fff;
    text-align: left;
}

.loca-ico {
    background-image: url(../images/pin.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 0 0 30px;
    background-color: #fff;
    text-align: right;
}

.popup-btn {
    font-size: var(--fontSize22);
}

/* Order Detail Popup -- END */

/* Order Search -- Start */

.search-orders {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 25px;
    background: #d3d0c9;
    padding: 20px;
    border-radius: 10px;
}

.search-order-left {
    width: 100%;
    width: auto;
}

.search-orders .button.reload-btn {
    float: right;
    padding: 18px 20px;
}

.search-orders input[type="text"],
.search-orders input.search-ord {
    width: 520px;
    margin: 0;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #323232;
    height: 52px;
    box-shadow: 1px 1px 6px rgba(60, 57, 46, 0.1);
}

.search-input-grp {
    display: flex;
    flex: 1 1 auto;
}

.search-orders input[type="text"]::placeholder,
.search-orders input.search-ord::placeholder {
    color: #323232;
}

.search-orders .button,
.search-orders input[type="button"],
.search-orders .button.resetbtn,
.search-orders button.resetbtn,
.search-orders input[type="button"].resetbtn {
    margin: 0 0 0 10px;
    padding: 12px 20px 12px;
    height: 52px;
}

.search-orders button.button {
    margin-left: -53px;
}

.search-orders .button i {
    line-height: 0.8;
}

/* Card Login Popup -- START */
.modal-pay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.pay-popup {
    position: fixed;
    text-align: center;
    top: 50%;
    max-width: 450px;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 3px solid #f3fdd6;
    border-radius: 5px;
    box-shadow: 1px 2px 3px 3px rgb(0 0 0 / 15%);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}

.modal-overly {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(40, 40, 40, 0.75);
}

.popup-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    opacity: .5;
    cursor: pointer;
}

.popup-close:hover {
    opacity: 1;
}

.popup-close::before,
.popup-close::after {
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2.2px;
    background-color: #000;
}

.popup-close::before {
    transform: rotate(45deg);
}

.popup-close::after {
    transform: rotate(-45deg);
}

.pay-inner {
    position: relative;
    padding: 50px 0 0;
}

.crd-logo {
    position: absolute;
    top: -30%;
    right: 0;
    left: 0;
    z-index: -1;
}

.crd-logo span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #332e20;
    border: 6px solid #fff;
    width: 120px;
    height: 120px;
    padding: 10px;
    border-radius: 50%;
    margin: 0 auto;
}

.crd-logo img {
    object-fit: contain;
    max-width: 75px;
}

.pay-popup form {}

.pay-popup .crd-title {
    text-align: center;
}

.pay-popup .crd-title span {
    position: relative;
    display: inline-block;
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 15px 0 0;
    margin: 0 0 25px;
}

.pay-popup .crd-title span::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    width: 65%;
    height: 2px;
    background: #ec2f45;
}

.pay-popup .crd-row>div {}

.modal .close {
    position: absolute;
    top: 14px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: inline-block;
    opacity: .5;
    cursor: pointer;
    z-index: 99;
}

.modal .close:hover {
    opacity: 1;
}

.modal .close::before,
.modal .close::after {
    content: "";
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2.2px;
    background-color: #000;
}

.modal .close::before {
    transform: rotate(45deg);
}

.modal .close::after {
    transform: rotate(-45deg);
}

.crd-row {}

.crd-flx {}

.crd-flx label {}

.crd-flx .form-control {
    color: #32325d;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border: 0px;
    border-bottom: 1px solid #e4e4e4;
}

.crd-flx .form-control::placeholder {
    color: #c0c0c0;
    opacity: 1;
}

.crd-flx .form-control:-ms-input-placeholder {
    color: #c0c0c0;
    opacity: 1;
}

.crd-flx .form-control::-webkit-input-placeholder {
    color: #c0c0c0;
    opacity: 1;
}

.pay-popup button {
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border-radius: 0px;
    padding: 10px 7px;
    margin: 20px 0 10px;
    letter-spacing: 1.5px;
    text-transform: capitalize;
}

/* Card Login Popup -- END */


/* Small ToolTip Popup -- START */
.sm-popup {
    position: fixed;
    max-width: 255px;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999;
}

.sm-popup#success .sm-popup-innr {
    background: #c8eec9;
}

.sm-popup#warning .sm-popup-innr {
    background: #ffcb6c;
}

.sm-popup#danger .sm-popup-innr {
    background: #ffabab;
}

.sm-popup-innr {
    position: relative;
    user-select: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1px 2px 14px 0px rgb(0 0 0 / 20%);
}

.sm-popup .popup-close {
    opacity: 1;
    width: 15px;
    height: 15px;
    right: inherit;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.sm-popup .popup-close::before,
.sm-popup .popup-close::after {
    height: 15px;
}

.sm-popup#success .popup-close::before,
.sm-popup#success .popup-close::after {
    background: #048307;
}

.sm-popup#warning .popup-close::before,
.sm-popup#warning .popup-close::after {
    background: #654100;
}

.sm-popup#danger .popup-close::before,
.sm-popup#danger .popup-close::after {
    background: #650000;
}

.popup-close:hover::before,
.popup-close:hover::after {
    background: #f90000 !important;
}

.sm-cnt {
    max-width: calc(100% - 30px);
    margin-left: 15px;
    text-align: left;
}

.sm-cnt p {
    font-size: 16px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.sm-popup#success .sm-cnt p {
    color: #043105;
}

.sm-popup#warning .sm-cnt p {
    color: #774d00;
}

.sm-popup#danger .sm-cnt p {
    color: #4a0404;
}

/* Small ToolTip Popup -- END */

/** Loader button**/
.loader-btn {
    position: relative;
    background: #ec2f45 !important;
    color: #ec2f45 !important;
}

.loader-btn::after {
    position: absolute;
    content: '';
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    border: 3px solid #FFF;
    border-radius: 50%;
    border-top: 3px solid #FFF;
    border-bottom: 3px solid #ec2f45;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.refresh-btn {
    position: absolute;
    top: calc(50% - 12px);
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.refresh-btn i {
    font-size: 22px;
}

.rdt_Table .rdt_TableBody .rdt_TableCell a.refresh-btn.spin {
    color: #ec2f45;
}

.spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* DatePicker -- START */
.react-datepicker {
    font-family: 'Calibri' !important;
}

.react_timepicker .css-bg1rzq-control {
    min-height: 49px;
    border-radius: 0;
}

.react_timepicker .css-1szy77t-control {
    min-height: 49px;
    border-radius: 0;
}

.react_timepicker .css-1hwfws3 {
    padding: 2px 22px;
}

.react_timepicker input[type=text] {
    height: 100%;
}

.react_datepicker .css-yk16xz-control {
    min-height: 48px;
    border-radius: 0;
}

.react_datepicker .css-1pahdxg-control {
    min-height: 48px;
    border-radius: 0;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-family: 'Calibri-Bold';
    font-weight: normal
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2rem;
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

/*
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #8cbc06 !important
}
*/
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    /*  background-color: #fb9f0e !important; */
}

.react-datepicker__header {
    background-color: #8cbc06 !important;
    color: #fff;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #8cbc06 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #8cbc06 !important;
}

.react-datepicker__navigation {
    margin: 0
}

.react-datepicker__navigation--previous {
    border-right-color: #fff !important;
    box-shadow: none;
}

.react-datepicker__navigation--next {
    border-left-color: #fff !important;
    box-shadow: none;
    opacity: 1;
}

.react-datepicker__navigation-icon::before {
    border-color: #fff !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff !important;
}

.react-datepicker__day-name {
    color: #fff !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #8cbc06;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    background-color: #8cbc06 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    transform: translate(40px, 0px) !important;
}

.react-datepicker-popper[data-placement^=bottom] svg.react-datepicker__triangle {
    fill: #8cbc06 !important;
    color: #8cbc06 !important;
    transform: rotate(180deg) translateY(-1px) !important;
}

/* DatePicker -- END */

/* Common Popup -- START */

.modal-table {
    border: 1px solid #959699;
    margin: 10px 0 15px;
}

.modal-table table {
    margin-bottom: 0px;
}

.modal-table thead {}

.modal-table thead tr {}

.modal-table thead tr td {}

.modal-table tbody tr {}

.modal-table tbody tr td {}

.modal-table tbody tr td .form-control {
    position: relative;
    font-size: 18px;
    color: #2B2E34;
    width: 50px;
    border: 0px;
    outline: 0px;
    z-index: 19;
    max-width: 100px;
}

.modal-table tbody tr td .form-control::-webkit-inner-spin-button,
.modal-table tbody tr td .form-control::-webkit-outer-spin-button {
    opacity: 1;
    background-color: transparent;
    background: transparent;
    color: #2B2E34;
}

.modal-table .table_overflow {
    padding: 0 15px;
}

.dt-flx {
    display: flex;
    flex-flow: row wrap;
    margin: 10px auto 10px 0;
    justify-content: space-between;
    align-items: flex-end;
}

.dt-flx>div {
    flex: 1 1 auto;
}

.dt-flx>div .dt-innr {
    margin-right: 15px;
}

.dt-flx>div .dt-innr>span {
    display: inline-block;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 10px;
}

.dt-flx .delivery-date {
    max-width: 300px;
}

.dt-flx .delivery-time {}

div.cont-shoping {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    background: #f6f6f6;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin: 0 0 20px;
}

.cont-shoping p {
    font-size: 18px;
}

.cont-shoping a.button {
    margin: 0px 0;
    padding: 10px 15px;
}

/* Common Popup -- END */

/* Custom popup - start */

.custom-alert-pop {
    position: fixed;
    /*    display: none;*/
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
}

.custom-alert-pop::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
}

.custom-alert-container {
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 440px;
    max-width: 90%;
    transform: translate(-50%, -50%);
    animation: popanimi .5s forwards;
}

@keyframes popanimi {
    0% {
        opacity: 0;
        top: 65%;
    }

    100% {
        opacity: 1;
        top: 50%;
    }
}

.custom-alert-header {
    background: #ec2f45;
    color: #FFF;
    padding: 14px 20px 14px;
    border-radius: 4px 4px 0 0;
    text-align: center;
    position: relative;
}

.paylater-popup .custom-alert-header {
    background: #544a2c;
}

.custom-alert-header h4 {
    color: #FFF;
    margin: 0;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    text-transform: uppercase;
}
.custom-alert-header:has(.close-alert){
    padding: 14px 40px 14px 25px;
}

.custom-alert-header .close-alert {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    top: 10px;
    right: 14px;
    z-index: 2;
    cursor: pointer;
    transition: all 0.4s ease;
}
.custom-alert-header .close-alert:hover{
    border-color: #ec2f45;
    background: #ec2f45;
}
.custom-alert-header .close-alert::after, .custom-alert-header .close-alert::before {
    position: absolute;
    content: "";
    background: #ebebeb;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}
.custom-alert-header .close-alert::before {
    height: 60%;
    width: 2px;
}
.custom-alert-header .close-alert::after {
    width: 60%;
    height: 2px;
}

.custom-alert-body {
    padding: 25px 30px 60px;
    background: #FFF;
    text-align: center;
    min-height: 120px;
    border-radius: 0 0 4px 4px;
}

.custom-alert-body p {
    margin: 0 0 10px;
}

.custom-alert-body .alert-buttons {
    text-align: center;
    padding: 15px 0 0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
}

.alert-buttons a.button {
    padding: 8px 24px 8px;
    border-radius: 3px;
    margin: 5px 5px 0;
}


/* Custom popup- END */

.dt-title {
    font-size: var(--fontSize32);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0 0 10px;
    padding-right: 20px;
}

.dt-title .dts-title {
    font-size: var(--fontSize32);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
    margin: 0 10px 0 0;
}

.dt-title span {
    font-size: 16px;
}

.dt-flx>div .dt-innr select {
    padding: 10px;
}

main.main-section {
    position: relative;
    width: 100%;
    min-height: 300px;
    padding-bottom: 20px;
}


/* Loader -- START */
.load-flx {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    left: 0;
    top: 0;
}

.pay-popup .load-flx {
    position: relative;
}

.load-smll #loading {
    border-width: 3px;
}

.load-lag #loading {
    width: 70px;
    height: 70px;
    border-width: 10px;
}

#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 8px solid rgb(236 47 69 / 50%);
    border-radius: 50%;
    border-top-color: #ec2f45;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* Loader -- END */

/* My Account -- START */

.acc-block {
    position: relative;
    margin: 50px 0;
}

.acc-table {}

.acc-table table {}

.acc-table thead {}

.acc-table thead tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.acc-table thead tr td {
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.2;
    padding: 0 5px 0 0;
    color: #2B2E34;
}

.acc-table thead tr td .fa {}

.acc-table tbody {}

.acc-table tbody tr {
    padding: 0 0 5px;
    border-bottom: 1px solid #959699;
}

.acc-table tbody tr td {
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-size: 15px;
    color: #2B2E34;
    padding: 5px 5px 5px 0px;
    vertical-align: middle;
}

.acc-table tbody tr td .acc-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 6px 15px;
    color: #fff;
    padding: 6px 15px;
    background: #ec2f45;
    transition: all .5s ease-in-out;
}

.acc-table tbody tr td .acc-btn:hover {
    background: #000;
    transition: all .5s ease-in-out;
}

.acc-table tbody tr.no-line {
    border-bottom: 0px;
}

.acc-table tbody tr.total td span {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

/* My Account -- END */

/* Login Screen Design -- Jagan */

.login {
    padding: 0%;
    margin: 75px auto 55px;
    height: 100%;
}

.login .form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 360px;
    margin: 0 auto 50px;
    padding: 30px 30px 20px;
    text-align: center;
    box-shadow: 0px 4px 7px 1px #ccc;
}

.form .login-form h2 {
    font-size: 30px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    margin: 0 0 20px;
}

.form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
}

.form input:focus {
    background: #dbdbdb;
}

.form button {
    font-size: 18px;
    font-family: 'Proxima Nova Rg';
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 10px 15px;
    text-transform: capitalize;
    border-radius: 0px;
}

.form .login-form button {
    min-width: 160px;
    border-radius: 4px;
    padding: 12px 15px;
}

.form button:active {
    background: #395591;
}

.form span {
    font-size: 75px;
    color: #4b6cb7;
}

.err-msg {
    font-size: 14px;
    background: #b62334;
    display: inline-block;
    position: relative;
    text-align: left;
    padding: 4px 10px;
    color: #FFF;
    border-radius: 4px;
    margin: -2px 0 0;
}

.err-msg::before {
    position: absolute;
    content: '';
    border: 8px solid;
    border-color: transparent transparent #b62334 transparent;
    top: -15px;
}

.success-msg {
    font-size: 14px;
    color: green;
    text-align: left;
}

.txt-green {
    color: green;
    cursor: pointer;
}

.sweet-alert button {
    color: #fff;
    display: inline-block;
    font-family: 'Proxima Nova Rg';
    border-radius: 0px;
    padding: 9px 24px;
    cursor: pointer;
    border: 0px;
    font-size: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.react-datepicker-popper {
    z-index: 9 !important;
    padding-top: 0 !important;
}

.login .form form.login-form input {
    margin: 8px 0 10px;
}

.login .back-to-cat {
    margin: 0 0 15px;
    text-align: center;
}

.login-form .disclaimer-info {
    display: inline-block;
    font-size: 20px;
    position: relative;
    top: 2px;
    margin-left: 8px;
    color: #6a6465;
    cursor: pointer;
}
.login-form .disclaimer-info:hover {
    color: #ec2f45;
}

.form .login-form .check-box {
    text-align: left;
    padding: 8px 0 0;
}
.form .login-form .check-box label {
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    padding: 4px 0 0 28px;
}
.form .login-form .check-box label:before{
    padding: 8px;
}

/* Login Screen Design End -- Jagan */

/* My Account - Strat  */
.header-tab-menu {
    margin: 10px 0 0px;
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #f4f4f4;
}

.header-tab-menu>a {
    padding: 12px 20px 12px;
    background: #f4f4f4;
    margin: 0 4px;
    font-size: 18px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
    border-radius: 4px 4px 0 0;
}

.header-tab-menu>a.active {
    background: #ec2f45;
    color: #FFF;
}

.header-tab-menu>a:first-of-type {
    margin-left: 0;
}

.header-tab-menu>a:last-of-type {
    margin-right: 0;
}

.my-acc-block {
    position: relative;
    margin: 50px 0;
}

.myact-wrap .form-field.form-btn-field {
    padding: 5px 0;
}

.myact-wrap .client-details {
    padding: 20px;
    background: #f6f6f6;
}

.myact-wrap .client-detail-form {
    flex-flow: column;
    margin: 0;
}

.myact-wrap .client-detail-form .form-field {
    width: 100%;
    margin: 4px 0 4px;
    max-width: 540px;
    position: relative;
}

.my-acc-hdr-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px;
    width: 100%;
    max-width: 540px;
}

.my-acc-edit a {
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    text-decoration: underline;
}

.pwd-chg-form-wrap .form-field {
    width: 100%;
    margin: 4px 0 4px;
    max-width: 340px;
    position: relative;
}

.myact-wrap .client-detail-form .form-field label,
.pwd-chg-form-wrap .form-field label {
    display: inline-block;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 2px 2px 6px;
}

.pwd-chg-form-wrap .input-field span.icon-eye {
    right: 0px;
    bottom: 10px;
}

.form-btn-field .button {
    margin: 0 6px;
    line-height: 1.2;
}

.form-btn-field .button:first-of-type {
    margin-left: 0;
}

.form-btn-field .button:last-of-type {
    margin-right: 0;
}

.edit-opt {
    position: absolute;
    display: block;
    left: calc(100% + 15px);
    min-width: 80px;
    top: 13px;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    display: none;
}

/* cookie bar */
.cookie-bar {
    padding: 6px 30px 7px 20px;
    text-align: center;
    position: fixed;
    background: #fcc254;
    color: #332e20;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 100;
}

.cookie-bar a {
    color: #332e20;
    text-decoration: underline;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    ;
}

.cookie-bar a:hover {
    color: #ec2f45;
}

a.cookie-close {
    position: absolute;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    top: 9px;
    right: 10px;
    font-size: 20px;
}

.cookie-close::before,
.cookie-close::after {
    content: "";
    position: absolute;
    left: 15px;
    top: 4px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #332e20;
}

.cookie-close:hover::before,
.cookie-close:hover::after {
    background-color: #ec2f45;
}

.cookie-close::before {
    transform: rotate(45deg);
}

.cookie-close::after {
    transform: rotate(-45deg);
}

/* My Account - END  */

/** scroll to top **/
.scrolltop {
    position: fixed;
    right: 10px;
    bottom: 120px;
    transition: all 0.4s ease;
    visibility: hidden;
    opacity: 0;
    z-index: 90;
}

.scrolltop.show {
    opacity: 1;
    visibility: visible;
}

.scrolltop .disbl_href_action {
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    text-align: center;
    background: #332e20;
    color: #FFF;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.scrolltop .disbl_href_action span {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 42px;
}

.scrolltop .disbl_href_action span i {
    position: absolute;
    left: 50%;
    top: 47.5%;
    transform: translate(-50%, -50%);
}

.scrolltop .disbl_href_action span i.ars {
    display: none;
}

.scrolltop .disbl_href_action:hover {
    background: #ec2f45;
}

/** scroll End **/


.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.login-form>p {
    font-size: 15px;
    padding: 0px 0px 15px;
}

.login-form p.err-msg {
    padding: 5px 12px;
}

.login-form p.err-msg::before {
    bottom: -14px;
    transform: rotate(180deg);
    top: auto;
}

/*** Offer free product popup ***/
.offer_products .info-item {
    padding: 0 0 12px;
    margin: 0 0 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.offer_products .check-box {
    margin-bottom: 0px;
}

.offer_products .check-box label:before {
    border-radius: 0px;
    top: -3px;
    padding: 8px;
}

.offer_products .info-item .info-innr {
    padding: 6px 10px;
}

.btn-item {
    text-align: center;
}

.price-strike {
    position: relative;
    width: fit-content;
}

.price-strike::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: #ec2f45;
    left: 0;
    bottom: 7px;
    transform: skewY(20deg);
}

.free-prod-qty-val {
    position: relative;
    width: 80px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.free-prod-qty-val input[type="text"] {
    padding: 5px 20px;
    text-align: center;
    margin: 0;
    border-radius: 0;
    height: 30px;
    pointer-events: none;
}

.free-prod-qty-val .qty-val-action {
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 100%;
    top: 0px;
    text-align: center;
    font-size: 18px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    line-height: 1.7;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.free-prod-qty-val .qty-val-minus {
    margin-right: 7px;
    left: 2px;
}

.free-prod-qty-val .qty-val-plus {
    margin-left: 7px;
    right: 2px;
    line-height: 1.8;
}

.free-prod-qty-val .qty-val-action:hover {
    color: #ec2f45;
    border-color: #ec2f45;
}

.free-prod-qty-val.unchecked::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.6);
}

.alert-message {
    text-align: center;
    color: #ec2f45;
}

span.required {
    font-size: 1.325em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #ec2f45;
}

.checkout-cart-wrap .checkout-table .checkout-table.free-prod-pop {
    width: 100%;
}

.checkout-table.free-prod-pop table thead tr td {
    width: initial;
}

.checkout-table.free-prod-pop table thead tr td {
    font-size: 17px;
}

.checkout-table.free-prod-pop table tr td .check-box {
    margin: 6px 0 0;
}

.checkout-table.free-prod-pop .check-box input:checked+label:after {
    top: 1.4px;
}

.checkout-table.free-prod-pop table tr td .check-box label:before {
    top: -3px;
}

.checkout-table.free-prod-pop table tbody tr td.disabled {
    opacity: 0.22;
    pointer-events: none;
}

.checkout-table.free-prod-pop table tbody tr td {
    padding: 10px 5px 10px 10px;
}

.free-prod-wrap.info-poup .order-top h4 {
    padding: 0 28px 0;
}

.free-prod-wrap.info-poup .order-top h4 span {
    display: block;
    font-size: 17px;
    padding: 8px 0 0;
}

.free_product_list .cart-prod-amt,
.free_product_list .prod-title-txt {
    color: #ec2f45;
}

/*** ***/
.account-total-records {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0px 0px 10px;
    padding: 8px 15px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
}

/*** change pwd ***/
.change-password-popup .form-field label {
    display: inline-block;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 8px;
}

.change-password-popup .btn-field {
    text-align: center;
}

form.client-detail-form.pwd-change-form {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: flex-end;
}

.pwd-change-form button.button {
    height: 38px;
    padding: 6px 30px 5px;
    margin: 0px 0px 15px;
}

.err-msg.pass-error-msg {
    position: relative;
    width: 100%;
    text-align: center;
    border-radius: 0;
    padding: 4px 20px;
}

.err-msg.pass-error-msg::before {
    display: none;
}

/** alert section **/
.alert-section {
    display: block;
    padding: 0 0 5px;
}

.alert-section p {
    padding: 8px 20px;
    margin: 5px 0;
    border-radius: 4px;
    font-size: 15px;
}

.alert-section p.success {
    background: #aadfa6;
}

.alert-section p.error {
    background: #eabcbf;
}

.pass-input {
    padding: 6px 4px;
}

.free_product_list span.prt-txt,
.free_product_list span.unit-sale-txt,
.free_product_list td.prt-price,
.free_product_list td.table-tol {
    color: #ec2f45;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    /*  color: black; */
}

.free_product_list .cart-prod-qty .qty-val-action {
    visibility: hidden;
}

.checkout_testing_text {
    text-align: center;
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 24px;
}

.free-prod-wrap .popup_popupHead__PInfv {

    background: #fcc254;
}

/**** new add 24-04-23 *****/
.free-edit-desp {
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    display: block;
    text-align: center;
    margin: 10px 0 10px;
}

.cart-table-body>ul>li.free_product_list {
    background: #fff5d1;
    padding: 12px 0px 12px 10px;
}

.cart-table-body ul ul.free_product_list-inner {
    overflow: hidden;
    height: auto;
    width: 100%;
}

.cart-table-body>ul.main-cat-cart .free_product_list-inner li.free_product_list-item {
    display: flex;
    padding: 8px 24px 8px 0px;
    position: relative;
    border-top: 1px solid #f1e6bf;
    border-bottom: none;
}

.cart-table-body>ul.main-cat-cart .free_product_list-inner li.free_product_list-item .cart-prod-delete {
    top: 14px;
    right: 8px;
    width: 14px;
    height: 16px;
}

.cart-table-body>ul.main-cat-cart .free_product_list-inner li.free_product_list-item .cart-prod-qty input.qty-number,
.free_product_list-inner li.free_product_list-item .cart-prod-qty input.qty-number {
    padding: 3px 4px 3px;
}

.cart-table-body>ul.main-cat-cart .free_product_list-inner li.free_product_list-item .cart-prod-qty,
.free_product_list-inner li.free_product_list-item .cart-prod-qty {
    padding-right: 8px;
    padding-left: 2px;
}

.free-title-blk {
    position: relative;
    padding: 4px 26px 14px 2px;
    width: 100%;
}

.free-title-blk a.free_edit_btn {
    position: absolute;
    right: 2px;
    top: 2px;
    font-size: 14px;
}

.free-title-blk a.free_edit_btn:hover {
    position: absolute;
}

.free-title-blk h4 {
    margin: 0;
    font-size: 15px;
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
}

.free-title-blk h4 span {
    display: block;
    font-size: 13px;
}

/*** **/
.free_product_list_wrap {
    background: #fff5d1;
}

.checkout-table table tbody tr.free_product_list {
    background: #fff5d1;
}

tr.free_product_list_wrap td tr.free_product_list {
    display: table;
    width: 100%;
}

tr.free_product_list_wrap td tr.free-title-blk {
    display: table;
    width: 100%;
}

tr.free_product_list_wrap td tr.free_product_list td {
    width: 130px;
}

tr.free_product_list_wrap td tr.free_product_list td.prt-title {
    padding-left: 12px;
    max-width: 320px;
}

tr.free_product_list_wrap td tr.free_product_list td.prt-title .prtf-flx {
    width: 290px;
}

.free_product_list_wrap .free-title-blk {
    position: relative;
    padding: 0px 26px 6px 12px;
    width: 100%;
    text-align: left;
}

.checkout-order .free_product_list_wrap .free-title-blk {
    padding: 0px 26px 6px 6px;
}

.checkout-table table tbody tr.free_product_list_wrap>td {
    padding: 0;
}

.checkout-table table thead tr td.order_remarks {
    font-size: 15px;
}

/**** *****/

/** Order placement Popup **/
.odr-progress-list {
    list-style: none;
}

.odr-progress-list li {
    padding: 10px 0 15px 40px;
    position: relative;
}

.odr-progress-list li h4 {
    font-size: 17px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.odr-progress-list li .progress-status {
    position: absolute;
    left: 0;
    top: 10px;
}

.odr-progress-list li .progress-status::after {
    position: absolute;
    content: '';
    width: 18px;
    height: 11px;
    border-left: 4px solid #15ae68;
    border-bottom: 4px solid #15ae68;
    transform: rotate(-45deg);
    left: 2px;
    top: 8px;
}

.odr-progress-list li .progess-load::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
}

.odr-progress-list li .progess-load::after {
    position: absolute;
    content: '';
    border: 3px solid #ec2f45;
    border-radius: 50%;
    border-top: 3px solid #ec2f45;
    border-bottom: 3px solid #FFF;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 2;
}

.odr-progress-list li .progess-load.progress-status::after {
    width: 20px;
    height: 20px;
}

.order_remarks {
    font-weight: 400;
}

.checkout-table table tbody tr td .free-title-blk h4 {
    width: calc(100% - 100px);
}

.checkout-table table tbody tr td .free-title-blk span.ico-del {
    text-align: right;
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 5px;
}

.checkout-table table tbody tr td .free-title-blk span.ico-del .txt-del {
    padding: 5px 9px 4px;
}

.free-title-blk.free-select-blk {
    padding: 4px 6px 4px 2px;
}

.free-title-blk.free-select-blk h4 {
    width: calc(100% - 80px);
}

.free-title-blk.free-select-blk h4 i,
.free-title-blk h4 i {
    display: inline-block;
    padding: 2px;
    cursor: pointer;
}

.free-title-blk.free-select-blk h4 i:hover,
.free-title-blk h4 i:hover {
    color: #ec2f45;
}

.free-title-blk.free-select-blk .free_edit_btn {
    font-size: 13px;
    display: inline-block;
    padding: 4px 10px 3px;
    margin: 10px 0 0;
    background: #070802;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 4px;
}

.free-title-blk.free-select-blk .free_edit_btn:hover {
    background: #ec2f45;
}

.prt-zoom-over {
    /*
    width: 320px;
    height: 320px;
    */
    position: absolute;
    z-index: -1;
    left: 60px;
    bottom: 0;
}

/*** payment-popup ***/
.info-poup.payment-popup .info-bdy {
    min-height: 120px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.order-progress-wrap {
    width: 100%;
}

/*** site maintanance ***/
.site-maintanance-wrap {
    margin: 0;
    padding: 0px 0 40px;
    text-align: center;
}

.site-maintanance-wrap p {
    font-size: 24px;
    line-height: 1.3;
    font-weight: bold;
    color: #ec2f45;
}

.site-maintanance-wrap p span {
    display: block;
    font-size: 42px;
}

.site-maintanance-page .Layout_rgtFlx__2H8Mq {
    display: none;
}

.site-maintanance-page a.cart-innr {
    pointer-events: none;
}

.site-maintanance-page a.cart-innr img.cart-back {
    filter: blur(3px) opacity(0.75);
}

.maintanance-icon {
    padding: 0px 0 15px;
}

.maintanance-icon img {
    max-width: 90px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.site-maintanance-wrap p a {
    color: #ec2f45;
    text-decoration: underline;
}

/*** footer cookies ***/
footer .cookie-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

footer .cookie-bar p {
    font-size: 14px;
}

a.button.cookie-btn {
    margin: 2px 10px -2px;
    text-decoration: none;
    padding: 6px 12px;
    background: #332e20;
    color: #fcc254;
    font-size: 13px;
    text-transform: initial;
    display: inline-flex;
    font-weight: 400;
}

/**** Select - drop Filter option *****/
.select-drop {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    position: relative;
    justify-content: flex-end;
}

.select-drop select {
    margin: 0;
    height: 50px;
    padding: 6px 12px;
    background-image: url(../images/down.png);
    background-repeat: no-repeat;
    background-position: center right 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.select-drop select::-ms-expand {
    display: none;
}

.select-drop a.filter-btn {
    padding: 16px 20px 16px;
    width: 170px;
    margin: 0px 0 0px;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
    background: #FFF;
    color: #222;
    position: relative;
    box-shadow: 0px 4px 9px 0px rgba(32, 32, 32, 0.08);
}

.select-drop a.filter-btn:hover,
.select-drop a.filter-btn.active {
    background: #ec2f45;
    color: #FFF;
}

.select-drop a.filter-btn.button .filter-icon {
    position: absolute;
    right: 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    border-left: 1px solid #b4b4b4;
    padding-left: 10px;
}

.select-drop a.filter-btn.button.active .filter-icon {
    border-left: 1px solid #e4e4e4;
}

.filter-bx {
    position: absolute;
    padding: 20px 15px 75px;
    background: #FFF;
    border-radius: 6px;
    right: 0px;
    width: 100%;
    top: 62px;
    display: none;
    z-index: 999;
    box-shadow: 0px 4px 9px 0px rgba(32, 12, 2, 0.18);
}

.filter-bx.show {
    display: block;
    -webkit-animation: slide_up ease-out 0.4s 0s;
    animation: slide_up ease-out 0.4s 0s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    width: 380px;
}

.filter-bx::before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    background: #FFF;
    right: 20px;
    top: -6px;
    transform: rotate(45deg);
}

.filter-btns {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 10px;
    padding: 10px 0 5px;
    border-top: 1px solid #d4d4d4;
    left: 0;
    text-align: center;
    z-index: 2;
}

.filter-btns button.button {
    margin: 5px 5px 5px;
    height: auto;
    font-size: 14px;
    padding: 8px 10px 8px;
    min-width: 80px;
}

.filter-btns button.button.clear-btn {
    background: #565656;
}

.filter-btns button.button.clear-btn:hover {
    background: #000;
}

.no-filter-list .filter-bx.show {
    padding: 10px;
}

.filter-bx .no-filter {
    text-align: center;
    padding: 20px 0;
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.filter-options {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.filter-options::-webkit-scrollbar {
    width: 6px;
}

.filter-options::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.filter-options::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
}

.filter-options a.filter-val {
    padding: 8px 14px 8px;
    width: calc(50% - 8px);
    margin: 3px 4px 6px;
    background: #f8f8f8;
    border: 2px solid #f4f4f4;
    color: #222;
    border-radius: 22px;
    font-size: 14px;
    position: relative;
    overflow: hidden;
}

.filter-options a.filter-val:hover {
    background: #f2f2f2;
    color: #222;
}

.filter-options a.filter-val.active {
    color: #ec2f45;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    border-color: #ef6474;
}

.filter-options a.filter-val span.remove-filter {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: -10px;
    visibility: hidden;
    opacity: 0;
    top: 8px;
    transform: rotate(45deg);
    transition: all 0.4s ease;
    z-index: 2;
}

.filter-options a.filter-val.active:hover span.remove-filter {
    right: 10px;
    visibility: visible;
    opacity: 1;
}

.filter-options a.filter-val span.remove-filter::after,
.filter-options a.filter-val span.remove-filter::before {
    position: absolute;
    content: '';
    background: #ec2f45;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.filter-options a.filter-val span.remove-filter::before {
    width: 2px;
    height: 100%;
}

.filter-options a.filter-val span.remove-filter::after {
    width: 100%;
    height: 2px;
}

.filter-option-mob .select-drop {
    display: none;
}

@-webkit-keyframes slide_up {
    0% {
        -webkit-transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes slide_up {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

.free-prod-wrap .checkout-table table tbody tr:last-child {
    border-bottom: none;
}

.free-prod-wrap .info-bdy.offer_products {
    position: sticky;
    bottom: 20px;
    padding: 15px 0 0px;
    background: #FFF;
    border-top: 1px solid #e5e5e5;
}

.free-prod-wrap .info-bdy.offer_products::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 20px;
    top: 100%;
    background: inherit;
}

.free-prod-wrap .info-bdy.offer_products .btn-item button {
    margin: 0;
}

.free-prod-wrap .info-bdy.offer_products .alert-message {
    margin: 0 0 15px;
}

.checkout-table table tbody tr td.prt-title .prtf-flx .prt-txt h4 span.color-red {
    display: inline;
    font-size: 1.325em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.my-order-details-pop .checkout-table table tbody tr td.data-product-hide {
    min-width: 360px;
}

.my-order-details-pop .checkout-table table tr td:nth-of-type(3) {
    text-align: center;
}

.my-order-details-pop .checkout-table table tbody tr:nth-child(even) {
    background: #fbfbf2;
}

/**** home new banner ****/
.home-banner {
    margin: 50px 6px 0;
}

.home-banner .ban-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #fcfbf8;
    border: 1px solid #f2f3e1;
    border: 1px solid #dedfcd;
    position: relative;
    cursor: pointer;
}

.ban-wrap .ban-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 60%;
    position: relative;
}

.ban-left .ban-img img.banImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ban-wrap .ban-left::after {
    position: absolute;
    content: '';
    width: 3px;
    height: 50px;
    background: #fcc254;
    right: 15px;
}

.ban-left .ban-img {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.ban-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 38%;
    padding: 0 10px;
}

.ban-left-text {
    padding: 0;
    padding-left: 20px;
}

.ban-left-text h3 {
    margin: 0;
    font-size: 36px;
    text-transform: uppercase;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    color: #fcc254;
    text-align: center;
}

.ban-left-text h3 span {
    display: block;
    font-size: 20px;
    color: #332e20;
}

.ban-right .ban-right-text {
    width: 180px;
}

.ban-right .ban-right-text p span {
    display: block;
}

.ban-right-btn {
    padding: 0;
}

.ban-right-btn a.button {
    background: #fcc254;
    color: #332e20;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.ban-right-btn a.button:hover,
.ban-wrap:hover .ban-right-btn a.button {
    background: #ec2f45;
    color: #FFF;
}

/** Home new banner II **/
.hm-banner-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 50px 6px 0;
}

.hm-left-ban,
.hm-right-ban {
    width: 49%;
    position: relative;
    height: 90px;
    cursor: pointer;
}

.hm-right-ban.full-width {
    width: calc(100% - 15px);
    margin: 50px auto 0;
}

.hm-left-ban {
    margin-right: 0px;
}

.hm-right-ban {
    margin-left: 0px;
}

.hm-left-ban .hm-ban-img,
.hm-right-ban .hm-ban-img {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
}

.hm-left-ban .hm-ban-img img.banImg,
.hm-right-ban .hm-ban-img img.banImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 25%;
    opacity: 0.4;
}

.hm-right-ban .hm-ban-img img.banImg {
    opacity: 0.65;
}

.hm-left-ban .hm-ban-content,
.hm-right-ban .hm-ban-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.hm-right-ban .hm-ban-content {
    justify-content: flex-end;
    padding: 0 20px;
}

.hm-ban-content .ban-left-text {
    width: 30%;
    padding: 10px 15px;
    position: relative;
}

.hm-ban-content .ban-left-text::before {
    display: none;
}

.hm-ban-content .ban-left-text::after {
    position: absolute;
    content: '';
    width: 3px;
    height: 50px;
    background: #fcc254;
    right: 16px;
    top: 2px;
}

.hm-ban-content .ban-left-text h3 {
    font-size: 19px;
    letter-spacing: 0.5px;
    color: #FFF;
}

.hm-ban-content .ban-left-text .ofr-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 10px;
    top: -6px;
    background: url(../images/offer-icon.png);
}

.hm-ban-content .ban-left-text h3 span {
    display: block;
    font-size: 14px;
    color: #FFF;
}

.hm-ban-content .ban-right-text {
    width: 40%;
    color: #FFF;
}

.hm-right-ban .hm-ban-content .ban-right-text {
    width: 51%;
    padding: 0 10px;
}

.hm-ban-content .ban-right-text h3 {
    font-size: 20px;
    color: #fcc254;
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
    margin: 0;
    display: -webkit-box;
    max-width: 240px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hm-ban-content .ban-right-text h3 span {
    display: block;
    font-family: 'Proxima Nova Rg';
    font-size: 18px;
}

.hm-left-ban:hover .ban-right-btn a.button,
.hm-right-ban:hover .ban-right-btn a.button {
    background: #ec2f45;
    color: #FFF;
}

.hm-right-ban.full-width .hm-ban-content {
    justify-content: center;
}

.hm-right-ban.full-width .hm-ban-content .ban-right-text {
    width: 20%;
}

.hm-banner-wrap {
    gap: 18px;
}

.home-ban-even-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.home-ban-even-wrap .home-banner {
    margin: 50px 0px 0;
    width: 99.33%;
}

.home-ban-odd-wrap .hm-banner-wrap {
    justify-content: space-between;
}



/**** Free Product List Page ****/
.checkout-header h1.page-title {
    width: calc(100% - 200px);
}

.page-title span {
    font-size: 30px;
    padding-left: 4px;
}

.free-intro-txt {
    padding: 5px 0 20px;
}

.free-product-wrap h5 {
    font-weight: bold;
    font-size: 22px;
    ;
    margin: 0 0 20px;
    color: #ec2f45;
}

.free-intro-txt p {
    margin: 0 0 10px;
}

.free-intro-txt ul li {
    padding: 6px 0 6px 30px;
    position: relative;
}

.free-intro-txt ul li::before {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-top: 2px solid #e22d42;
    border-right: 2px solid #e22d42;
    left: 10px;
    top: 11px;
    transform: rotate(45deg);
}

.free-product-list {
    display: block;
    width: 100%;
    padding: 0px 0px 0px;
    margin: 0 0 30px;
    max-width: 780px;
    border: 1px solid #e5e5e5;
}

.free-product-list li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.free-product-list li.prod-list-header {
    background: #f6f6f6;
}

.free-product-list li:nth-child(even) {
    background: #FFF;
}

.free-product-list li:last-child {
    border-bottom: none;
}

.free-product-list li .free-prod-id {
    display: flex;
    flex-wrap: wrap;
    width: 380px;
    align-items: center;
    padding-right: 15px;
    position: relative;
}

.free-product-list li.prod-list-header h4 {
    font-weight: 100;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.free-product-list li .free-prod-id h4 {
    margin: 0;
    line-height: 1.2;
}

.free-prod-id .free-prod-img {
    width: 82px;
    height: 81px;
    margin-right: 20px;
    cursor: pointer;
}

.free-prod-id .free-prod-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.free-prod-id .free-prod-title {
    display: inline-block;
    width: calc(100% - 102px);
}

.free-prod-id .free-prod-title h4 {
    margin: 0;
    color: #424242;
    cursor: pointer;
}

.free-prod-id .free-prod-title h4:hover {
    color: #e22d42;
}

.free-prod-id .free-prod-title h4 span {
    display: inline-block;
}

.free-product-list li .free-prod-unit {
    padding: 0 15px;
    min-width: 120px;
    text-align: center;
}

.free-product-list li .free-prod-info {
    padding: 0 15px;
    min-width: 40px;
    text-align: center;
}

.prt-info img {
    width: 18px;
}

.free-product-list li .free-prod-info span.prt-info {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
}

.free-product-list li .free-prod-unit h4,
.free-product-list li .free-prod-info h4,
.free-product-list li.prod-list-header h4 {
    margin: 0;
    color: #424242;
}

.free-product-list li .free-prod-price {
    padding-left: 15px;
    min-width: 100px;
    text-align: left;
}

.free-product-list li .free-prod-price h4 {
    margin: 0;
    color: #424242;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

/*** free offer atg ***/
.checkout-table table tbody tr td span.offer-tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3px 6px;
    background: #fff8df;
    background: #f6f6f6;
    width: max-content;
    border-radius: 5px;
    margin: 4px 0 0;
    color: #eb2f47;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.checkout-table table tbody tr td span.offer-tag>img.tag-icon {
    width: 16px;
    margin-right: 5px;
}

/**** free list popup ****/
.free-prod-list-pop .free-product-list li .free-prod-id {
    width: 260px;
}

.free-prod-list-pop .free-prod-id .free-prod-img {
    width: 52px;
    height: 51px;
    margin-right: 12px;
}

.free-prod-list-pop .free-prod-id .free-prod-title {
    width: calc(100% - 64px);
}

.free-prod-list-pop .order-top {
    padding: 0px 40px 0px 0;
}

.free-prod-list-pop .order-top h4 span {
    display: initial;
    padding: 0;
    font-size: 20px;
}

/*** filter new show ***/
.filter-field-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px;
}

.filter-field-wrap h3 {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    margin: 0 0 10px;
}

.filter-show-bx {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.filter-show-bx .filters {
    padding: 8px 26px 8px 12px;
    background: #f2f2f2;
    border-radius: 6px;
    margin: 3px 6px 3px 0;
    font-size: 13px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    position: relative;
    cursor: pointer;
}

.filter-show-bx .filters a.filter-x {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 9px;
    transform: rotate(45deg);
    transition: all 0.4s ease;
    z-index: 2;
}

.filter-show-bx .filters a.filter-x::after,
.filter-show-bx .filters a.filter-x::before {
    position: absolute;
    content: '';
    background: #000;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.filter-show-bx .filters a.filter-x::before {
    width: 2px;
    height: 100%;
}

.filter-show-bx .filters a.filter-x::after {
    width: 100%;
    height: 2px;
}

.filter-show-bx .filters.clr-all {
    padding: 8px 12px 8px 12px;
    background: #ffcfcf;
}

.filter-show-bx .filters:first-of-type {
    margin-left: 0px;
}

.filter-show-bx .filters:last-of-type {
    margin-right: 0px;
}

.payment-opt {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0 10px;
}

.payment-opt .pay-accept {
    padding: 7px 10px 5px;
    border: 2px solid #dfd9d9;
    border-radius: 6px;
    position: relative;
}

.payment-opt .pay-accept::before {
    position: absolute;
    content: 'We Accept';
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    background: #FFF;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    padding: 2px 6px;
}

.pay-accept img.pay-meth-img {
    max-width: 150px;
}

/*** checkout with sub header ***/
.checkout-table.checkout-order table tbody tr.prt-head-tr {
    background: #f7f5ee;
}

.checkout-table table tbody tr.prt-head-tr td.prt-head,
.checkout-table.checkout-order table tbody tr.prt-head-tr td.prt-head {
    padding: 8px 4px;
}

.checkout-table.checkout-order table tbody tr.prt-subhead-tr td span.strong {
    font-size: 17px;
}

.checkout-table.checkout-order table tbody tr.prt-subhead-tr td.prt-head {
    padding: 6px 4px;
}

/*** my orders filter ***/
.fltr-dt-wrap .css-qbdosj-Input {

    margin: 2px 0 0px;
}

.filter-date-range {
    position: relative;
    padding: 10px 0 15px;
    z-index: 3;
}

.search-orders .fltr-dt-wrap input[type="text"] {
    height: 38px;
}

.fltr-dt-wrap {}

.fltr-dt-wrap label {
    display: block;
    margin: 0 0 8px;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
}

.search-orders .fltr-dt-range input[type="text"] {
    width: 100%;
    height: 40px;
    background: transparent;
    box-shadow: 1px 1px 6px rgba(60, 60, 60, 0.03);
}

.fltr-dt-range {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0 0;
    display: none;
}

.fltr-dt-range.show {
    display: flex;
}

.fltr-dt-range .fltr--date {
    width: 47.5%;
    position: relative;
}

.fltr-dt-range .fltr--date span {
    display: block;
    margin: 0 0 6px;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    font-weight: 100;
    letter-spacing: -0.4px;
}

.fltr-dt-range .fltr--date span.date-icon {
    position: absolute;
    right: 6px;
    bottom: 6px;
    z-index: -1;
}

/*** fot cookie ***/
.cookies-on footer {
    padding: 40px 0 45px;
}

/*** ***/
.prod_tableQty {
    position: relative;
    width: 72px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkout-table table tbody tr td.prt-qty .prod_tableQty input {
    width: 72px;
    height: 24px;
    padding: 2px 22px;
    font-size: 13px;
}

.prod_tableQty .prod_QtyVal {
    position: absolute;
    font-size: 20px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    top: 0px;
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
}

.prod_tableQty .prod_QtyVal.prod_QtyMin {
    line-height: 21px;
    left: 1px;
}

.prod_tableQty .prod_QtyVal.prod_QtyPlus {
    right: 1px;
}

.checkout-table table tbody tr td span.color-red {
    display: inline-block;
    font-size: 1.125em;
}

/*** Toottip css ****/
/* title-tip down */
.title-tooltip {
    display: inline-block;
    position: relative;
    padding: 2px;
}

.title-tooltip[data-title]:hover {
    position: relative;
}

.title-tooltip[data-title]:hover:before {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99999;
    content: attr(data-title);
    margin-top: 0.5em;
    padding: 0.5em;
    /* width: 200px; */
    width: max-content;
    max-width: 180px;
    font-size: 12.5px;
    overflow: hidden;
    word-wrap: break-word;
    font-family: 'Proxima Nova Rg';
    color: #FFF;
    text-align: center;
    background-color: #222;
    box-sizing: border-box;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.title-tooltip[data-title]:hover:after {
    position: absolute;
    top: calc(100% - 5px);
    left: 50%;
    z-index: 99999;
    content: '';
    margin-left: -8px;
    width: 0;
    height: 0;
    border: 6px dashed transparent;
    border-bottom: 6px solid #222;
    font-size: inherit;
}

/* title-tip-up */
.title-up[data-title]:hover:before {
    top: auto;
    bottom: calc(100% + 8px);
    margin-top: 0;
    margin-bottom: 0.5em;
}

.title-up[data-title]:hover:after {
    top: calc(0% - 15px);
    right: calc(100% + 5px);
    border: 8px dashed transparent;
    border-top: 8px solid #222;
    margin-left: -12px;
}

/* title-tip-right */
.title-right[data-title]:hover:before {
    top: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.5em;
}

.title-right[data-title]:hover:after {
    top: 50%;
    left: 100%;
    margin-left: 0;
    margin-top: -0.125em;
    border: 0.25em dashed transparent;
    border-right: 0.25em solid #222;
}

/* title-tip-left */
.title-left[data-title]:hover:before {
    top: auto;
    left: auto;
    right: 100%;
    margin-top: 0;
    margin-right: 0.5em;
}

.title-left[data-title]:hover:after {
    top: 50%;
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-top: -0.125em;
    border: 0.25em dashed transparent;
    border-left: 0.25em solid #222;
}

/** tooltip top right **/
.title-up.right[data-title]:hover:before {
    right: auto;
    left: 20%;
}

.login-form p.err-msg {
    line-height: 1.3;
}

.login-form p.err-msg a {
    color: #FFF;
}

.login-form p.err-msg a:hover {
    color: #f5c0c6;
}

/* added by  k2b each word uppercase */
.free-product-wrap h5 {
    text-transform: initial;
}

.react-datepicker__day--keyboard-selected {
    color: #000 !important;
}

/* added by k2b vivek */
/*** Tootlpip END ****/

/* Code -- END */
.checkout-table table thead tr td:first-of-type {
    padding-left: 10px;
}

.react-datepicker-wrapper {
    width: 100%;
}

/* .loading-pdf {
    position: relative;
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.loading-pdf p {
    position: relative;
    transform: translate(0px, -50px);
}
.loading-pdf::after{
    position: absolute;
    content: '';
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    border: 3px solid #ec2f45;
    border-radius: 50%;
    border-top: 3px solid #FFF;
    border-bottom: 3px solid #ec2f45;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    z-index: 2;
} */

.pdf-container {
    width: 100%;
    height: 100vh;
    /* Full viewport height */
}

.pdf-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5em;
}

.dev-portal-tag {
    width: 100%;
    padding: 6px 0 6px;
    position: fixed;
    top: 0;
    left: 0;
    background: #d42929;
    text-align: center;
    color: #FFF;
    z-index: 99999;
    font-size: 13px;
}

.unauthorized-wrap {
    padding: 20px;
    margin: 50px 0 0;
    display: flex;
    background: #f7f7ef;
    border: 1px solid #e1d9d9;
    border-radius: 6px;
}

.unauthorized-wrap p,
.reset-pass-tokenerr p {
    display: flex;
    align-items: center;
}

.unauthorized-wrap p i,
.reset-pass-tokenerr p i {
    color: #e35e3f;
    display: inline-flex;
    margin-right: 10px;
    font-size: 1.5em;
}

.reset-pass-container {
    padding: 60px 0 50px;
    text-align: center;
}

.reset-pass-tokenerr {
    padding: 20px;
    margin: 10px 0 30px;
    display: inline-flex;
    background: #f7f7ef;
    border: 1px solid #e1d9d9;
    border-radius: 6px;
}

.reset-pass-container a.back-btn.button {
    padding: 12px 20px 12px 20px;
    color: #FFF;
}

.reset-pass-container h2 {
    font-size: 2.0em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
}

/**** change password legend ****/
.chg-pwd-lgd {
    width: calc(100% - 12px);
    max-width: 220px;
    display: flex;
    border-radius: 5px;
    position: absolute;
    right: 15px;
    top: calc(100% - 10px);
    background: #f4f4f4;
    padding: 10px 10px;
    z-index: 99;
    /* display:none; */
}

.chg-pwd-lgd::before {
    position: absolute;
    content: "";
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #f4f4f4 transparent;
    top: -16px;
    right: 10px;
}

.chg-pwd-list {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.chg-pwd-list li {
    font-size: 13px;
    padding: 2px 8px 2px 28px;
    position: relative;
    display: block;
    border-radius: 4px;
    text-align: left;
    color: #222;
    margin: 1px 4px 4px 0;
}

.chg-pwd-list li.unchecked {
    color: #dd5d6c;
    border-color: #dd5d6c;
}

.chg-pwd-list li.checked {
    color: #2bb930;
    border-color: #2bb930;
}

.chg-pwd-list li.checked::before {
    position: absolute;
    content: "\f058";
    font: normal normal normal 12px / 1 FontAwesome;
    margin-left: -20px;
}

.chg-pwd-list li.unchecked::before {
    position: absolute;
    content: "\f00d";
    font: normal normal normal 12px / 1 FontAwesome;
    margin-left: -20px;
}

.form-field {
    position: relative;
}

.error-msg {
    font-size: 14px;
    background: #b62334;
    display: inline-block;
    position: absolute;
    left: 10px;
    top: calc(100% - 8px);
    text-align: left;
    padding: 4px 10px;
    color: #FFF;
    border-radius: 4px;
    margin: -2px 0 0;
    z-index: 4;
}

.error-msg::before {
    position: absolute;
    content: '';
    border: 8px solid;
    border-color: transparent transparent #b62334 transparent;
    top: -15px;
}

.login .form .form-field {
    text-align: left;
}

.input-field span.icon-eye {
    cursor: pointer;
    position: absolute;
    right: 1px;
    bottom: 11px;
    width: 38px;
    height: 40px;
    background: #e5e5e5;
    text-align: center;
    line-height: 40px;
    border-radius: 0 5px 5px 0;
    font-size: 13px;
    color: #222;
}

.no-records-found {
    padding: 10px 20px;
    background: #f64257;
    border-radius: 6px;
    color: #FFF;
    margin: 15px 0 0;
}

.no-records {
    color: #f64257;
}

.flex-log-hdr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px;
}

.flex-log-hdr h4 {
    margin: 0;
}

.flex-log-hdr .log-date {
    font-size: 14px;
}

/*** ***/
.orders-dropdown {
    display: flex;
    flex-wrap: wrap;
    padding-right: 8px;
}

.orders-dropdown select {
    height: 52px;
    margin: 0;
    padding: 6px 24px 6px 12px;
    width: 180px;
    background-image: url(../images/down.png);
    background-repeat: no-repeat;
    background-position: center right 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    position: relative;
}

.orders-dropdown select option {
    padding: 6px 10px;
    font-size: 15px;
}

.table-search-reset {
    display: flex;
}

.table-search {
    position: relative;
    flex: 1 1 auto;
    margin: 0 10px 0 0;
    width: 480px;
}

.orders-drop-left .table-search {
    width: 360px;
}

.products-search-left .table-search-reset {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.orders-drop-left,
.products-search-left {
    display: flex;
    flex-wrap: wrap;
}

.search-orders.orders-search-bx input[type="text"],
.search-orders.orders-search-bx input.search-ord,
.search-orders.orders-search-bx select.search-ord {
    width: 100%;
}

.orders-search-bx .select-drop {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    flex-basis: calc(100% - 680px);
}

/***** thank you page ******/
.thank-section {
    margin: 0;
}

.thank-section .sm-container {
    max-width: 100%;
}

.thank-block {
    position: relative;
    text-align: center;
    width: 100%;
    background: #fff;
    border: 1px solid #eef2e2;
    box-shadow: 1px 2px 12px 3px rgb(0 0 0 / 1%);
    border-radius: 5px;
}

.thank-head {
    padding: 140px 10px 120px;
    background: #eef2e2;
}

.thank-ico {
    margin: 0;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 40px;
    left: calc(50% - 40px);
    border-radius: 50%;
    background: #9c0;
    line-height: 105px;
}

.thank-ico .fa {
    font-size: 50px;
    color: #FFF;
}

.thank-head h3 {
    font-size: var(--fontSize24);
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-weight: 100;
    margin: 5px 0 5px;
    font-size: 42px;
}

.thank-head p {
    font-size: 20px;
    color: #000;
}

.thank-body {
    padding: 30px 10px 60px;
}

.thank-body-inner {
    width: 100%;
    max-width: 940px;
    margin: -120px auto 0;
    background: #fff;
    border: 1px solid #eef2e2;
    box-shadow: 1px 2px 12px 3px rgb(0 0 0 / 4%);
    border-radius: 10px;
    padding: 20px;
}

.thank-details-wrap {
    text-align: left;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
}

.thank-details-title {
    display: block;
    width: 100%;
    padding: 10px 10px;
    border-radius: 5px 5px 0 0;
    background: #f6f6f6;
    border-bottom: 1px solid #e4e4e4;
}

.thank-details-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.thank-details-content .thank-details-item {
    padding: 10px 10px;
    border-right: 1px solid #e4e4e4;
    font-size: 15px;
}

.thank-details-content .thank-details-item:last-of-type {
    border-right: none;
}

.thank-details-content .thank-details-item span {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
}

.thank-page-bottom {
    margin: 0px 0 30px;
    /* border-top: 1px solid #e4e4e4; */
    /* padding: 30px 0 0; */
}

.thank-bot-links {
    margin: 0px auto 0px;
    /* border-top: 1px solid #e4e4e4; */
    padding: 30px 0 0;
    max-width: 380px;
}

.thank-bot-links p {
    font-size: 18px;
    color: #222;
}

.thank-details-content .thank-details-item p {
    font-size: 16px;
    color: #222;
}

.thank-bot-links a.t-links {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #969696;
    border-radius: 50%;
    line-height: 40px;
    margin: 0 0 10px;
}

.thank-bot-links p a {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
}

.thank-bot-links p a:hover {
    color: #f64257;
}

.thank-body-inner .loading-txt {
    display: none;
}

.thank-body-inner.loading-buffer {
    position: relative;
}

.thank-body-inner.loading-buffer::before {
    background: rgba(246, 246, 246, 0.92);
}

.thank-body-inner.loading-buffer::after {
    top: calc(15% - 25px);
    left: calc(50% - 20px);
    border: 4px solid #ec2f45;
    border-radius: 50%;
    border-top: 4px solid transparent;
    border-bottom: 4px solid #ec2f45;
    width: 40px;
    height: 40px;
}

.thank-body-inner.loading-buffer .loading-txt {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 3;
    top: calc(25% - 25px);
    left: 50%;
    transform: translate(-49%, -50%);
    font-size: 1.35em;
}

.std-links a,
a.std-links {
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
}

.std-links a:hover,
a.std-links:hover {
    color: #f64257;
}

.link-space {
    margin: 0 5px;
}

.thank-btn {
    font-size: 18px;
    font-weight: 400;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 16px 30px;
    margin: 40px 0 10px;
}

.delay {
    transition-delay: 30s;
}

.checkout-popup {
    width: 100% !important;
}

.cater-sec .acc-ttflx .checkout-title {
    text-transform: uppercase;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 1.6rem !important;
    line-height: 1.125rem !important;
}

#clientDetails .client-detail-bx {
    overflow: hidden;
    height: auto;
    height: 0px;
    transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
}

#clientDetails.show .client-detail-bx {
    height: auto;
    height: 155px;
}

.act-show {
    cursor: auto;
    cursor: pointer;
    display: block;
    position: relative;
}

.act-show::after {
    position: absolute;
    /*display: none;*/
    content: "";
    width: 12px;
    height: 12px;
    right: 2px;
    top: 2px;
    border-top: 2px solid #262626;
    border-right: 2px solid #262626;
    transform: rotate(135deg);
    transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
}

.show .act-show::after {
    transform: rotate(-45deg);
    right: 2px;
    top: 8px;
}

.user-log-name {
    display: block;
    padding: 0 15px 20px;
    font-size: 20px;
    text-align: center;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
}

.user-log-name span {
    font-family: 'Proxima-Nova-Bold';
}

form.login-form .user-log-name {
    padding: 0 0 8px;
    font-size: 16px;
}

form.login-form .user-log-name span {
    font-size: 16px;
    color: #000;
}

.re-odr-prod-ua h4 {
    margin: 0 0 10px;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.3px;
    font-size: 18px;
}

.re-odr-prod-ua span.icon {
    display: inline-block;
}

.re-odr-prod-ua span.icon i {
    color: #e35e3f;
    display: inline-flex;
    margin-right: 10px;
    font-size: 1.125em;
}

.free-prod-bg,
.free-prod-bg td {
    background: #fff5d1 !important;
}

.text-note {
    color: #8f7e80 !important;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.3px;
}

/**** Alerts msg ***/
.no-header .custom-alert-header {
    display: none;
}

.no-header .custom-alert-body {
    border-radius: 4px;
}

.alert-text {
    display: flex;
    max-width: fit-content;
    margin: 0px 0 10px;
    text-align: left;
    flex-wrap: wrap;
}

.alert-text .alert-icon {
    width: 32px;
    display: inline-block;
    text-align: center;
}

.alert-text .alert-txt {
    width: calc(100% - 32px);
    padding-left: 12px;
    color: #868686;
}

.alert-text .alert-txt .alert-title {
    font-size: 1.125em;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.2px;
    margin: 3px 0 6px;
    display: block;
}

.alert-text .alert-txt p:last-of-type {
    margin-bottom: 0;
}

.alert-text .alert-icon i {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alert-text.alert-danger .alert-icon i {
    color: #f44336 !important;
    border: 2px solid;
    border-radius: 50%;
    line-height: 22px;
    width: 32px;
    height: 32px;
}

.alert-text.alert-warning .alert-icon i {
    color: #f5c023 !important;
}

.alert-text.alert-success .alert-icon i {
    color: #4CAF50 !important;
}

.alert-text.alert-info .alert-icon i {
    color: #2196F3 !important;
}

.alert-text.alert-danger .alert-txt .alert-title {
    color: #f44336 !important;
}

.alert-text.alert-warning .alert-txt .alert-title {
    color: #f5c023 !important;
}

.alert-text.alert-success .alert-txt .alert-title {
    color: #4CAF50 !important;
}

.alert-text.alert-info .alert-txt .alert-title {
    color: #2196F3 !important;
}

.alert-text.alert-danger .alert-icon i::before {
    content: "\f00d";
    font-size: 24px;
    left: 1px;
    position: relative;
}

.alert-text.alert-warning .alert-icon i::before {
    content: "\f071";
}

.alert-text.alert-success .alert-icon i::before {
    content: "\f058";
}

.alert-text.alert-info .alert-icon i::before {
    content: "\f05a";
}

/****  New Products page *****/
.newProd-wrap .cater-item .cater-innr {
    min-height: 100px;
    max-height: 100px;
    background-size: cover !important;
    position: relative;
    padding: 0;
    color: #fcc254;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    font-size: 20px;
    line-height: 1.25;
}

.newProd-wrap .cater-item .cater-innr:hover {
    color: #FFF;
}

.newProd-wrap .cater-item .cater-innr[title="Back"] {
    color: #ec2f45;
    font-family: 'Proxima Nova Rg';
    letter-spacing: 0px;
    font-size: 28px;
}

.newProd-wrap .cater-item .cater-innr[title="Back"]:hover {
    color: #fff;
}

.newProd-wrap .cater-item .cater-innr .newProd-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
}

.newProd-wrap .cater-item .cater-innr .newProd-text {
    width: 100%;
    position: absolute;
    display: -webkit-box;
    max-width: 300px;
    padding: 0 10px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newProd-wrap .cater-item .cater-innr .newProd-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 25%;
    opacity: 0.65;
    transition: all 0.4s ease;
}

.newProd-wrap .cater-item .cater-innr:hover .newProd-image img {
    opacity: 0.35;
}

/*** Tugo Continue Shopping popup ***/
.info-bdy .cart-flx {
    padding: 20px 0;
}

.info-bdy .cart-flx .cart-item {
    max-width: 33.33%;
}

.info-bdy .cart-flx .cart-item a.cart-innr {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    overflow: hidden;
    box-shadow: -1px 2px 8px -1px rgb(51 46 32 / 8%);
    margin: 0 7px;
    transition: all .5s ease-in-out;
    border-radius: 5px;
    padding: 10px;
}

.info-bdy .cart-flx .cart-item a.cart-innr:hover {
    box-shadow: -1px 12px 24px -1px rgb(51 46 32 / 22%);
}

.info-bdy .cart-flx .cart-item a.cart-innr::before {
    content: "";
    height: 0;
    width: 0;
}

.info-bdy .cart-flx .cart-item img.cart-back {
    height: 130px;
    border-radius: 5px;
}

.info-bdy .cart-flx .cart-item .cart-top {
    position: relative;
    height: 45px;
    padding: 10px 0px 0px;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-bdy .cart-flx .cart-item .cart-top p {
    font-size: 15px;
    margin: 0;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    color: #020202;
    text-align: center;
    text-transform: uppercase;
}

/**** poserve form ***/
.promo-serve-form-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.promo-serve-bx {
    width: calc(100% - 300px);
}

.promo-serve-form {
    width: 100%;
    margin: 5px 0 10px;
    padding: 10px 0 10px 20px;
    border-left: 1px solid #e6e6e6;
}

.promo-serve-form .promo-serve-label {
    display: block;
    font-family: 'Proxima-Nova-Bold';
    letter-spacing: -0.4px;
    margin: 0 0 10px;
    width: 100%;
}

.promo-serve-fieldset {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
}

.promo-serve-fieldset .form-field {
    width: calc(50% - 7.5px);
}

.free-prodlistwrap-pop .free-prod-id .free-prod-title h4,
.free-prodlistwrap-pop .free-prod-id .free-prod-img {
    cursor: auto;
    color: #424242;
}

/**** hdr notificationbar ***/
.hdr-notification-bar {
    width: 100%;
    /*background: #e7cb61;*/
    padding: 0;
    margin: -19px 0 14px;
    position: relative;
    color: #332e20;
    border-top: 1px solid #444;
}

.hdr-notification-bar p {
    text-align: center;
}

.close-bar {
    position: absolute;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.close-bar::before,
.close-bar::after {
    content: "";
    position: absolute;
    left: 15px;
    top: 4px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #332e20;
}

.close-bar:hover::before,
.close-bar:hover::after {
    background-color: #ec2f45;
}

.close-bar::before {
    transform: rotate(45deg);
}

.close-bar::after {
    transform: rotate(-45deg);
}

.notification-buttons {
    display: none;
    padding: 25px 0 5px;
    text-align: center;
}

.notification-content {
    padding: 15px 40px 15px 20px;
    background: #fcc254;
}

.notify-txt span.icon {
    display: none;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 42px;
    border-radius: 50%;
    background: #ffffff;
    color: #f1b94e;
    font-size: 20px;
    box-shadow: 0px 0px 8px rgba(159, 116, 34, 0.15);
}

.notification-buttons a.button.ok-btn {
    margin: 0px 6px 0px;
    text-decoration: none;
    padding: 8px 12px;
    background: #332e20;
    min-width: 70px;
    color: #fcc254;
    font-size: 13px;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    font-weight: 400;
    font-family: 'Proxima-Nova-Bold';
}

.hdr-notification-bar.notification-Popup.popup-all {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hdr-notification-bar.notification-Popup.popup-all .notification-content {
    padding: 25px 30px 15px 30px;
    /*position: absolute;
    top: 50%;
    left: 50%; */
    z-index: 4;
    width: 90%;
    max-width: 480px;
    background: #f3ecdb;
    border-radius: 14px;
    -webkit-animation: growIn .5s forwards;
    animation: growIn .5s forwards;
    animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

.hdr-notification-bar.notification-Popup.popup-all .notification-content .notify-txt {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hdr-notification-bar.notification-Popup.popup-all .notification-buttons a.button.ok-btn {
    color: #FFF;
}

.hdr-notification-bar.notification-Popup.popup-all .notification-buttons a.button.ok-btn:hover {
    background: #fcc254;
    color: #332e20;
}

.notification-Popup.popup-all .notify-txt span.icon {
    display: inline-block;
    margin: -15px 0 15px;
}

.notification-Popup.popup-all .notify-txt p {
    width: calc(100% - 0px);
}

.notification-Popup.popup-all .notification-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
}

.hdr-notification-bar.notification-Popup.popup-all .notification-buttons {
    display: block;
}

body.popup-all {
    overflow: hidden;
}

.popup-all footer {
    z-index: 9;
}

@-webkit-keyframes growIn {
    0% {
        -webkit-transform: scale(0.8);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1.2);

    }

    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@keyframes growIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);

    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delivery-date .title-up[data-title]:hover:before {
    bottom: calc(100% + 1px);
}

.delivery-date .title-up[data-title]:hover:after {
    top: calc(0% - 7px);
}

/*** skeleton loading ***/
.is-loading {
    width: 100%;
}

.is-loading,
.is-loading *:not(.image, h2, p) {
    background: #FFF !important;
}

.is-loading .image,
.is-loading h2,
.is-loading p {
    background: #eee;
    background: linear-gradient(110deg, #e6e6e6 8%, #f5f5f5 18%, #e6e6e6 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: 1.5s shine linear infinite;
    animation: 1.5s shine linear infinite;
    margin: 0 0 10px;
}

.is-loading .image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.is-loading p {
    height: 20px;
}

.is-loading p:nth-of-type(5n + 1) {
    height: 40px;
    width: 65%;
}

.is-loading p:nth-of-type(5n + 2) {
    width: 80%;
}

.is-loading p:nth-of-type(5n + 3) {
    width: 90%;
}

.is-loading p:nth-of-type(5n + 4) {
    width: 70%;
}

.is-loading p:nth-of-type(5n + 5) {
    width: 60%;
    margin-bottom: 50px;
}

@-webkit-keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

/***** Product offer amount *****/
.checkout-table table tbody tr td.prt-price .amt-ofr-all {
    font-size: 0.95em;
    text-align: right;
    width: fit-content;
    clear: both;
    position: relative;
    display: inline-block !important;
    padding: 0px 0px 2px;
    text-decoration: line-through;
}

.free-product-list li .free-prod-price h4 .amt-ofr-all {
    font-size: 0.95em;
    clear: both;
    position: relative;
    display: block;
    padding: 0px 8px 2px 0px;
    text-decoration: line-through;
}

/** empty cart **/
.cart-table-body>ul.main-cat-cart li.cart-empty {
    justify-content: center;
    width: 100%;
    min-height: 300px;
    background: url(../images/no-product.png) scroll center bottom no-repeat;
    background-size: 160px;
    padding: 20px;
}

.my-order-details-pop .checkout-table table thead.ord-grand-total tr td .tot-ofr-applied {
    font-size: 12px;
    display: block;
    padding-right: 5px;
    font-style: italic;
}
.tot-ofr-applied {
    display: block;
    font-size: 12px;
    font-family: 'Proxima-Nova-Bold';
    font-style: italic;
    text-align: right;
}
.no-category-found {
    line-height: 1.2;
}
.no_product_found .no_product_txt .no-category-found span {
    font-size: 1em;
    text-transform: uppercase;
    margin: 0px 0 0;
    display: inline;
    padding: 0;
}
.subhead {
    display: block;
    font-size: 16px;
    text-transform: initial;
    padding: 6px 0 0;
    line-height: 1.25;
}

.disclaimer-note {
    text-align: left;
}
.disclaimer-note ul {
    padding: 0 0 0 20px;
    list-style: disc;
}
.disclaimer-note ul li {
    padding: 0px 0 15px;
}

.no-found-free-prod-info {
    text-align: center;
    background: #fbfbfb;
    border-radius: 8px;
    padding: 15px 0 30px;
}